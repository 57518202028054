@import './PageCommon.scss';

$iaoVeryLightGreen: rgba(153, 204, 51, 0.12);

.popup-editor-basic {
    margin-top: 0;
    background-color: #f3f9e6; /* Same as hovered but with the opacity baked in */
    height: 2.55em;
    min-height: 100%;
    z-index: 65536;
}

.catalog-manager {
    opacity: 0;
    max-width: 0;
    max-height: 0;
    transition: opacity 0.3s;
    display: none;

    &.in {
        max-width: 100%;
        max-height: 100vh;
        opacity: 1;
        display: block;
    }

    .iao-controls {
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.3s, transform 0.2s;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s;
        margin-top: -2px;
        margin-right: 5px;
        pointer-events: none;

        &.iao-controls-inline {
            display: inline-block;
            position: relative;
            margin: 0;
            height: 1em;
            z-index: 100;

            > .drag-handle {
                margin-top: -0.5em;
                cursor: move;
            }
        }
    }

    .row {
        > .drag-handle {
            position: absolute;
            right: 5px;
            top: calc(50% - 1.1em);
            cursor: move;
            display: none;
            border: none;
            background-color: transparent;
            font-size: 12px;
            cursor: move;
        }

        &.active {
            > .drag-handle {
                display: block;
                opacity: 0.7;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .drag-handle {
        cursor: move !important;
    }

    .explorerTree {
        padding-left: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        border: 1px solid #ccc;
        border-radius: 2px;

        > .data-catalog-tree-root {
            &::before {
                display: none;
            }

            & > .i-name {
                .group-tree-hint {
                    display: block;
                    padding-left: 29px;
                    font-size: 0.9em;
                    font-style: italic;
                }

                & ~ ul > li:first-child::before {
                    height: 2em;
                    top: -1em;
                    z-index: 2000;
                    border-left: 1px solid #bbb;
                    left: -1px;
                }

                .group-label {
                    font-weight: bold;
                    display: block;
                    float: right;
                    width: calc(100% - 1.7em - 20px);
                    margin: 0 20px 10px 0;
                }
            }

            .btn-group {
                & > .btn + .dropdown-toggle {
                    padding-left: 3px;
                    padding-right: 3px;
                    border-left: 1px solid #bbb;
                }

                .dropdown-toggle ~ .dropdown-menu {
                    background-color: buttonface;
                    font-size: 13px;
                    border-radius: 2px;
                }
            }

            li.ind {
                > .ind-controls {
                    position: absolute;
                    top: 3px;
                    right: 5px;
                }

                & > .i-name {
                    margin-right: 60px;
                    display: inline-block;
                    word-break: normal;
                    min-height: 1.8em;
                }

                .ia-layers-icon {
                    > i:nth-of-type(1) {
                        color: #000;
                    }

                    > i:nth-of-type(2) {
                        color: var(--ia-app-hi-color);
                    }

                    > i:nth-of-type(3) {
                        color: #333;
                    }
                }
            }

            & > div.widgetIndicators,
            & > div > ul > li,
            div.widgetIndicators > ul > li {
                padding-top: 2px;
            }

            > .igroup-children {
                > .child-theme:last-child {
                    padding-bottom: 3em;
                }
            }
        }
    }

    .explorer-layers-list {
        min-height: 4em;
        max-height: 8em;
        overflow-x: hidden;
        overflow-y: scroll;
        border: 1px solid #ccc;
        border-radius: 2px;
        padding: 10px 0 10px 35px;
        transition: height 0.3s;
        margin-bottom: 0;

        .popupEditor {
            @extend .popup-editor-basic;
            margin-top: 0;
            box-shadow: none;
            border: none;
            height: 100%;
        }

        > .geo-selector {
            margin-left: 20px;
            padding: 5px 5px 5px 5px;
            cursor: pointer;

            > a {
                margin-left: 5px;
                width: calc(100% - 130px);
                display: inline-block;
            }

            &::before {
                top: 7px;
            }

            > .control-label {
                padding-top: 0;
            }

            &.active::after {
                @extend .iao-after-active-item;
            }

            > .control-label {
                width: calc(100% - 210px);
            }

            > .iao-controls {
                top: 0;
            }
        }
    }

    .hide-empty .child-theme.empty-theme {
        display: none;
    }

    .igroup-children {
        height: 0;
        opacity: 0;
        transition: height 0.3s, opacity 0.35s;
        margin-top: 0;
        overflow: hidden;
    }

    .open {
        > .igroup-children {
            height: auto;
            opacity: 1;
        }
    }

    .explorer-tree-panel-controls {
        /*position: absolute;
            right: 20px;
            top: 1px;
            font-size: 0.75em;*/
        opacity: 0.4;
        transition: all 0.2s;

        &:hover {
            opacity: 1;
        }
    }

    .explorer-tree-panel {
        transition: all 0.2s;

        .explorer-tree-panel-controls {
            position: absolute;
            right: 30px;
            top: 1px;
            display: none;
        }

        &.full-screen {
            position: fixed;
            left: 0;
            top: 130px;
            width: 100vw;
            height: calc(100vh - 130px);
            background-color: #fff;
            z-index: 1010;

            .explorer-tree-panel-controls {
                display: block;
            }
            /*> .explorer-tree-panel-controls {
                        position: absolute;
                        right: 25px;
                        top: -25px;

                        > .btn {
                            border: none;
                        }
                    }*/
        }
    }

    .data-catalog-tree-root {
        > .i-name {
            display: none;
        }

        .drop-target-active {
            /*background-color: var(--ia-app-hi-color);
            color: #fff;
            box-shadow: 0 0 0 3px var(--ia-app-hi-color);*/
            @extend .iao-node-hovered;

            .fa-folder-open::after,
            .fa-folder::after {
                content: '\f067';
                position: absolute;
                left: -0.7em;
                top: 0;
                color: var(--ia-app-hi-color);
                font-weight: bold;
                font-size: 0.7em;
            }

            &.drop-target-active-copy {
                .fa-folder-open::after,
                .fa-folder::after {
                    content: '\f24d';
                    font-weight: normal;
                }
            }
        }

        .widgetIndicators {
            > .igroup-name + .popupEditor {
                @extend .popup-editor-basic;
                min-height: 2em;
                max-height: 2.35em;
                top: -1px;
            }
        }

        .theme-before-drop-zone {
            position: absolute;
            width: 100%;
            top: -7px;
            left: 1.2em;
            height: 7px;
            background-color: transparent;
            z-index: 1001;

            &.drop-target-active {
                background-color: var(--ia-app-hi-color) !important;
                border: none;
                opacity: 0.9;

                &::before {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    font-size: 14px;
                    content: '\f100';
                    position: absolute;
                    left: -1.2em;
                    top: -3px;
                    color: var(--ia-app-hi-color) !important;
                }
            }
        }

        > .theme-before-drop-zone {
            top: -32px;
            left: -52px;
            width: 64px;
            height: 60px;
            border-radius: 99%;
            background-color: rgba(200, 200, 200, 0.1);
        }

        li.ind {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &:not(.active):hover {
                background-color: rgba(200, 200, 200, 0.2);
                border-top: 1px solid rgba(200, 200, 200, 0.2);
                border-bottom: 1px solid rgba(200, 200, 200, 0.2);
            }

            &.drop-target-active {
                border-top: 3px solid var(--ia-app-hi-color);
            }

            .indicatorBoxLabel {
                margin-left: 5px;
                width: calc(100% - 220px);
            }

            .popupEditor {
                @extend .popup-editor-basic;
                /*#indicatorHoverEditor_Date {
                        display: none;
                    }*/
            }

            > .iao-controls {
                top: 5px;
            }

            input:focus ~ label {
                border: none;
                box-shadow: none;
            }
        }

        .ind-by-date-link {
            display: none;
        }

        > .igroup-children:empty {
            &::before {
                content: 'You need to add one or more root themes to get started...';
                padding: 10px 10px 10px 40px;
                display: block;
            }

            &::after {
                font-family: 'Font Awesome 5 Free';
                content: '\f071';
                font-weight: bold;
                font-size: 1.36em;
                display: block;
                position: absolute;
                left: 10px;
                top: 5px;
            }
        }
    }

    .popupEditor {
        background-color: #f3f9e6; /* Same as hovered but with the opacity baked in */
        height: 1.2em;

        > .box-label {
            width: 2.5em;
            height: 100%;
            text-align: center;

            > span {
                transform: rotate(90deg);
                width: 35px;
                transform-origin: 0 0;
                display: block;
                font-size: 0.8em;
                position: absolute;
                right: -35px;
                background-color: rgba(255, 255, 255, 0.2);
                top: 1px;
                letter-spacing: 0.5px;
                padding-top: 5px;
                line-height: 1.1;
                text-align: center;
            }

            &::after {
                font-family: 'Font Awesome 5 Free';
                font-weight: bold;
                content: '\f105';
                position: absolute;
                right: -2px;
                z-index: 10;
                opacity: 0.4;
            }
        }
    }

    li:not(.tab-item).active {
        @extend .iao-node-hovered;

        .iao-controls {
            opacity: 1;
            transform: scale(1);
            pointer-events: auto;
        }

        &::after {
            @extend .iao-after-active-item;
            width: 1.5em;
            left: -1.5em;
        }
    }

    .data-catalog-tree-root li.ind.active {
        @extend .iao-node-hovered;

        .iao-controls {
            opacity: 1;
            transform: scale(1);
            pointer-events: auto;
            margin-top: 1px;
        }

        &::after {
            @extend .iao-after-active-item;
            width: 0;
            left: 0;
            opacity: 0;
        }

        &:last-child::after {
            opacity: 1;
        }
    }

    .widgetIndicators {
        &.active {
            > .igroup-name {
                .iao-controls {
                    opacity: 1;
                    transform: scale(1);
                    pointer-events: auto;
                    margin-top: 2px;
                }

                &::before {
                    content: '';
                    @extend .iao-node-hovered;
                    @extend .iao-after-active-item;
                    width: calc(100% + 20px);
                    min-height: calc(2em + 5px);
                    height: calc(100% + 5px);
                    position: absolute;
                    left: -20px;
                    top: -2px;
                    pointer-events: none;
                }
            }
        }

        &:not(.active) {
            > .igroup-name:hover::before {
                content: '';
                width: calc(100% + 20px);
                height: calc(2em + 7px);
                position: absolute;
                left: -20px;
                top: -5px;
                pointer-events: none;
                background-color: rgba(200, 200, 200, 0.2);
            }
        }

        > .igroup-name {
            > a {
                border: none;
                width: calc(100% - 260px);
                padding: 5px 0;
            }
        }

        > ul {
            overflow: visible; /* To allow tooltips out - everything else just gets collapsed anyway */
        }
    }

    .popupEditor {
        min-height: 35px;
        width: calc(100% - 2px);
        margin-top: -5px;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;

        > .form-control {
            height: 100%;
            border-top: none;
            border-bottom: none;
            box-shadow: inset 0 0 3px $iaoVeryLightGreen;
        }
    }
}

.catalog-manager-page {
    .catalog-manager .explorer-tree-panel,
    .catalog-manager .explorer-layers-panel,
    .draggable-tree-item {
        /* Turn off (visual) checkboxes - too much UI noise */
        input[type='checkbox']:not(.native):checked + label::before,
        .checkbox input[type='checkbox']:not(.native):checked ~ label::before,
        input[type='checkbox']:not(.native) + label::before,
        input[type='radio'] + label::before,
        .checkbox input[type='checkbox']:not(.native) ~ label::before,
        .checkbox input[type='radio'] ~ label::before {
            display: none;
        }
    }

    .explorer-layers-panel {
        margin-bottom: 15px;
    }

    .modal {
        .btn-primary {
            font-weight: normal;
            letter-spacing: normal;
        }

        .instance-level-metadata-table {
            tr:not(.instance-level) {
                input,
                textarea,
                label {
                    font-style: italic;
                    color: #666;
                }

                label::after {
                    content: '*';
                }
            }
        }

        .layer-list {
            min-height: calc(100vh - 250px);
        }

        .multi-select {
            font-size: 1em;
            min-height: 9em;

            > option {
                padding: 2px 5px 2px 2em;

                &:nth-child(odd) {
                    background-color: #fafafa;
                }

                /*&::before {
                    content: '\f0c8';
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    margin-right: 4px;
                    position: absolute;
                    left: 5px;
                    perspective: 400px;
                    transform: rotateZ(45deg);
                }*/
            }
        }
    }

    .indicator-selector {
        tbody > tr > td:first-child {
            padding-left: 20px;
            width: 50%;
        }

        .date-field-label::after {
            content: ', ';
        }

        .date-field-label:last-child::after {
            content: '';
        }

        &.show-fields {
            .date-field-label::after,
            .date-field-label:last-child::after {
                content: ' [' attr(data-field-name) '] ';
                font-size: 0.9em;
                font-family: monospace;
            }
        }

        .date-field-box {
            width: 1px;
            position: relative !important;
            display: inline-block;
            height: 1px;
        }

        label.date-field-label {
            margin-left: 22px !important;
        }
    }

    .dates-table {
        .date-order,
        .indicator-url {
            word-break: break-all;
        }
        .indicator-url {
            font-size: 0.85em;
        }
        .sortable-header {
            white-space: nowrap;
            .btn {
                padding: 0 5px;
                vertical-align: bottom;
            }
        }
    }

    .task-in-progress-message {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 2px 15px;

        .progress-message-large {
            font-size: 1em;
        }
    }

    .lockout {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: not-allowed;
        background-color: rgba(100, 100, 100, 0.2);
        z-index: 1001;
    }
}

@import '../styles/_bootstrap-table.scss';

.modal {
    .sortable-header {
        cursor: pointer;
        .dropdown-menu {
            font-size: 0.85em;
            max-width: 40vw;
            min-width: 30vw;
            overflow-x: hidden;
            overflow-y: auto;
            > li {
                &:nth-child(odd) {
                    background-color: #f9f9f9;
                }
                > button {
                    white-space: normal;
                    text-align: left;
                }
            }
        }
    }

    .container {
        &.table {
            @extend .table;

            > .row:first-child > div {
                vertical-align: bottom;
                border-bottom: 2px solid #ddd;
                font-weight: bold;
            }

            > .row {
                &:nth-of-type(odd) {
                    background-color: #f9f9f9;
                }

                > div {
                    padding: 8px;
                    line-height: 1.42857143;
                    vertical-align: top;
                    border-top: 1px solid #ddd;
                }
            }
        }

        > .row {
            &.active {
                @extend .iao-node-hovered;
                border-left: 1px solid var(--ia-app-hi-color);

                > div {
                    background-color: transparent;

                    > .iao-controls {
                        opacity: 1;
                        transform: scale(1);
                        pointer-events: auto;
                        top: 0;
                    }
                }
            }

            &.marked-for-delete {
                background-color: rgba(255, 22, 22, 0.1) !important;
                font-size: 0.8em;
                color: rgba(255, 22, 22, 0.7);

                &::before {
                    font-family: 'Font Awesome 5 Free';
                    content: '\f2ed';
                    position: absolute;
                    left: -1em;
                    top: 0.6em;
                    color: rgba(255, 22, 22, 0.7);
                }

                > div {
                    text-decoration: line-through;
                    padding: 4px 8px;
                }
            }

            &.addition {
                font-weight: bold;

                &::before {
                    font-family: 'Font Awesome 5 Free';
                    content: '\f055';
                    position: absolute;
                    left: -1em;
                    top: 0.8em;
                    color: var(--ia-app-hi-color);
                }
            }
        }
    }
}
