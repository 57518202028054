$zIndexStandard: 1;
$zIndexBackground: $zIndexStandard - 1;
$zIndexForeground: $zIndexStandard + 1;
$zIndexPopup: 1000;
$zIndexToolTip: 65536;
$standardTextColor: #222;

/*#4A3875; is RB*/
$iaoHiColorNegative: #ffffff;
$iaoLoColor: #333;
$iaoActionColor: #f25a2b; /* e98d32 */
$iaoLightGrey: #ccc;
$iaoPageBackdrop: #fafafa;
$iaoPageActiveBackdrop: #3b3b3b;
$iaoPageContrastGrey: #bfbfbf;
$iaoPageBackgroundGrey: #efefef;
$iaoBorderGrey: #ccc;
$iaoBgGrey: #efefef;
$iaoBorderRadiusDef: 1px;

.iao-fas {
    font-family: 'Font Awesome 5 Free', FontAwesome; /*'Font Awesome 5 Free' in future maybe */
    font-weight: bold;
}

/* Ones that are not simple nesters */
.ia-app .swatch,
.iao-swatch {
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border: 1px solid #999999;
    border-radius: $iaoBorderRadiusDef;
    margin-left: auto !important;
    margin-right: auto !important;
    cursor: pointer;
}

.ia-app .tooltip-inner .swatch,
.tooltip-inner .iao-swatch {
    display: inline-block;
    width: 1em;
    height: 1em;
}

* {
    position: relative;
}

.ia-app {
    font-size: var(--ia-app-body-font-size);
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    body {
        font-size: var(--ia-app-body-font-size, 15px);
        padding: 0 !important;
        color: #222;
    }

    body.modal-open {
        /* Worth the bother?*/
    }

    #iaoPageMaskLoading {
        #iaoPageMaskIcon {
            width: auto !important;
        }

        > div:first-child::after {
            content: ' ';
            display: block;
            font-size: 16px;
            width: 20px;
            height: 20px;
            position: absolute;
            left: calc(50% - 10px);
            top: calc(50% - 10px);
            border-radius: 10px;
            border: 2px solid #fff;
            background-color: #333;
            background-image: url(../images/rb4arcgis-logo192.png) !important;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 16px;
            z-index: 9999;
        }
    }

    .topmost-surface {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 65530;

        > .draggable-row {
            background-color: #ffffff;
            border: 2px solid #ffffff;
            border-radius: 2px;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
            cursor: move;
            opacity: 0.8;
        }
    }

    .draggable-row {
        > div:first-child {
            label {
                cursor: move;
                width: 100%;
            }

            label::before {
                content: '\f58d';
                @extend .iao-fas;
                margin-right: 12px;
                color: #dcdcdc;
                font-size: 1em;
                position: absolute;
                left: -1.4em;
            }
        }
    }

    .draggable-row-over {
        border-top: 2px solid var(--ia-app-hi-color);
    }
    /* Reset some bootstrap values, can be overridden lower down */
    %btn-mixin {
        font-size: 1em;
        border-radius: 2px;
    }

    .btn {
        @extend %btn-mixin;
    }

    .btn-sm {
        font-size: 12px;
    }

    body, form, select, button, input, textarea,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .popover, .tooltip, .dropdown-menu > li > a /* bootstrap overrides */ {
        font-family: var(--ia-app-body-font);
        font-weight: 500;
        color: $standardTextColor;
    }
    /* bootstrap overrides */
    .btn,
    .btn:visited {
        color: inherit;
        text-decoration: none;
        border-radius: $iaoBorderRadiusDef;
    }

    .dropdown-menu {
        .btn-link {
            text-align: left;
        }
    }

    .form-control {
        border-radius: $iaoBorderRadiusDef;
    }

    .modal .modal-content {
        border-radius: calc(2 * #{$iaoBorderRadiusDef});
    }

    .number-control,
    .number-control ~ select.form-control {
        max-width: 4em;
        padding-left: 5px;
    }

    .iao-hidden {
        display: none;
        width: 0;
        height: 0;
    }

    .script-body-control {
        font-family: 'Source Code Pro', Roboto, Verdana, Arial, Helvetica, sans-serif !important;
        font-size: 13px;
    }
    /*button.close {
        font-size: 32px;
        margin-top: -0.25em !important;
    }*/
    .pagination > li > a,
    .pagination > li > span {
        border-color: $iaoPageContrastGrey;
    }

    .btn-primary,
    .dropdown-menu > .active > a,
    .pagination > .active > a,
    .pagination > .active > span,
    .pagination > .active > a:hover,
    .pagination > .active > span:hover,
    .pagination > .active > a:focus,
    .pagination > .active > span:focus,
    .mce-menu-item:hover,
    .mce-menu-item.mce-selected,
    .mce-menu-item:focus,
    .menu-item-set > .menu-item:hover {
        color: var(--ia-app-hi-color-negative, #fff);
        background-color: var(--ia-app-hi-color);
        border-color: var(--ia-app-hi-color-dark);
    }

    input[type='file'].d-n-d {
        border: 1px solid $iaoLightGrey;
        padding: 10px;
        width: 100%;
        border-radius: 1px;
    }

    input[type='file'].d-n-d::-webkit-file-upload-button {
        @extend %btn-mixin;
        position: relative;
        display: inline-block;
        font-family: var(--ia-app-body-font);
        /* No mixin because that merges the rulesets which causes IE and FF to lose the plot */
        font-size: 1.01em;
        border-radius: 2px;
    }

    input[type='file'].d-n-d::-webkit-file-upload-button:hover {
        color: #fff;
    }

    .hoverable {
        .hover-item:hover,
        .hover-item:focus,
        .hover-item.active {
            background-color: #f5f5f5;
            transition: background-color 0.3s;
        }
    }

    .text-primary {
        color: var(--ia-app-hi-color);
    }

    .btn-primary:hover,
    .btn-primary:focus {
        background-color: var(--ia-app-hi-color-light);
        border-color: var(--ia-app-hi-color-dark);
    }

    input[type='file']::-webkit-file-upload-button:hover {
        background-color: var(--ia-app-hi-color-light);
        border-color: var(--ia-app-hi-color-dark);
    }

    .btn-primary.disabled,
    .btn-primary.disabled.active,
    .btn-primary.disabled.focus,
    .btn-primary.disabled:active,
    .btn-primary.disabled:focus,
    .btn-primary.disabled:hover,
    .btn-primary[disabled],
    .btn-primary[disabled].active,
    .btn-primary[disabled].focus,
    .btn-primary[disabled]:active,
    .btn-primary[disabled]:focus,
    .btn-primary[disabled]:hover,
    fieldset[disabled] .btn-primary,
    fieldset[disabled] .btn-primary.active,
    fieldset[disabled] .btn-primary.focus,
    fieldset[disabled] .btn-primary:active,
    fieldset[disabled] .btn-primary:focus,
    fieldset[disabled] .btn-primary:hover {
        background-color: var(--ia-app-hi-color-light);
        border-color: var(--ia-app-hi-color-light);
        color: var(--ia-app-hi-color-negative, #fff);
    }

    .hover-opaque {
        color: var(--ia-app-hi-color);
        opacity: 0.6;
    }

    .hover-opaque[data-trigger='click'] {
        cursor: pointer;
    }

    .hover-opaque:hover {
        opacity: 1;
    }

    .navbar-nav > li > a,
    .navbar-nav > li > .btn {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        text-decoration: none;
    }

    .navbar-nav > li > a,
    .navbar-nav > li > .btn,
    .navbar-default .navbar-nav > li > a {
        color: $standardTextColor;
    }

    .navbar-default .navbar-nav > li.options-link > a {
        padding-left: 0;
        padding-right: 0;
        opacity: 0.25;
        display: none; /* for now */
    }

    .navbar-default .navbar-nav > li.options-link > a:hover {
        opacity: 0.75;
    }

    .navbar-nav > li > a.navbar-brand {
        padding-top: 0;
        margin-top: 0;
    }

    .navbar {
        min-height: 40px !important;
        max-height: 40px !important;
    }

    .navbar button.navbar-toggle {
        width: 32px;
        height: 32px;
        margin-top: 3px;
        line-height: 2px;
        font-size: 21px;
        font-weight: normal;
        padding: 0;
        color: $standardTextColor;
        z-index: 2000;
        /* margin-right: 200px; */
        float: left;
    }

    .navbar-header {
        z-index: $zIndexStandard + 1;
    }

    .navbar-brand {
        /*float: left;*/
        padding-top: 4px !important;
        padding-bottom: 5px !important;
        height: auto;
    }
    /* Development/test/staging flags */
    body.iaostagingreports::before,
    body.iaotestreports::before,
    body.iaoreportswebjobhost::before,
    body.iaostagingreports::after,
    body.iaotestreports::after,
    body.iaoreportswebjobhost::after {
        position: fixed;
        font-size: 44px;
        left: 10px;
        bottom: 0;
        z-index: 2000;
        color: #f25a2b;
        pointer-events: none;
    }

    body.iaotestreports::before {
        content: ' t e s t ';
        padding-left: 44px;
    }

    body.iaostagingreports::before {
        content: ' s t a g i n g ';
        padding-left: 44px;
    }

    body.iaoreportswebjobhost::before {
        content: ' w e b j o b s ~ p u b l i c a t i o n ~ h o s t ~ d o ~ n o t ~ s i g n ~ i n !';
        padding-left: 60px;
        bottom: 10vh;
        left: 5vw;
    }

    body.iaostagingreports::after,
    body.iaotestreports::after,
    body.iaoreportswebjobhost::after {
        @extend .iao-fas;
    }

    body.iaotestreports::after {
        content: '\f0ad';
    }

    body.iaostagingreports::after {
        content: '\f188';
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    body.iaoreportswebjobhost::after {
        content: '\f233';
        bottom: 10vh;
        left: 5vw;
    }

    .link-silent {
        height: 1px;
        width: 1px;
        position: absolute;
        overflow: hidden;
        top: -10px;
    }

    .tk-source-code-pro {
        font-family: 'Source Code Pro', Consolas, 'Courier New', monospace;
    }

    a.nodef,
    a.nodef:hover,
    a.nodef:visited {
        text-decoration: none;
        color: inherit;
    }

    .btn-nodef,
    .btn-nodef:disabled {
        @extend a.nodef;
        border: none !important;
        background: none !important;
    }

    .dropdown-menu .btn-nodef {
        width: 100%;
        text-align: inherit;
    }

    a img {
        border: none; /* IE - why oh why? */
    }

    input:disabled,
    select:disabled {
        background-color: #dfdfdf;
        color: #aaa;
        opacity: 0.5;
    }

    .control-exp-focus {
        width: 3em !important;
        opacity: 0.7;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .control-exp-focus:focus {
        width: 12em !important;
        opacity: 1;
    }

    label {
        font-weight: var(--ia-app-body-font-weight, 400);
    }

    .text-v-middle {
        margin-top: 0.5em;
        margin-bottom: 0.3em;
    }

    .t-10 {
        font-size: 10px;
    }

    .simpleInfoWindow /* ArcGIS Online popup window specific */ {
        font-size: 1em;
    }

    .simpleInfoWindow .content /* ArcGIS Online popup window specific */ {
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .simpleInfoWindow .content a /* ArcGIS Online popup window specific */ {
        font-size: var(--ia-app-body-font-size, 15px);
    }

    body {
        margin: 0;
    }

    h1 {
        letter-spacing: 0.01em; /* This is only relevant for Lato, not Droid */
    }

    .banner,
    .stripe {
        z-index: 40;
    }

    .bannerCentral,
    .map-full-screen-banner {
        background-color: $iaoLoColor;
        border-bottom: 10px solid var(--ia-app-hi-color);
        box-shadow: 0 0 1px 1px var(--ia-app-hi-color);
        padding: 10px 25px 10px 25px;
    }

    .bannerCentral {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .bannerBackdrop {
        /* background-color: rgba(0,0,255,0.2); */
        color: #fff;
        /* opacity: 0.2; */
    }

    .bannerCentral > div,
    .bannerCentral > p,
    .bannerCentral > span {
        position: relative;
    }

    .bannerCentral div,
    .bannerCentral p,
    .bannerCentral span {
        z-index: $zIndexForeground;
    }

    .bannerCentral::after,
    .map-full-screen-banner::after,
    .map-full-screen-popup-banner::after {
        content: ' ';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(../images/Online-Banner-Background.png);
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.15;
    }

    .iao-wave .ia-name,
    .modal .ia-product-logo {
        display: block;
        font-size: 1.1rem;
        font-family: Arial, Sans-Serif;
        float: left;
        width: 88px;
        height: 88px;
        min-width: 88px;
        text-align: center;
        padding-top: 32px;
        background-image: url(../images/rb4arcgis-logo192.png);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
        margin-right: 10px;
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        -webkit-transition: all 0.7s;
        transition: all 0.7s;
    }

    .modal .ia-product-logo::after {
        @extend .iao-fas;
        content: '\f069';
        color: #fff;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 22px;
    }

    .iao-wave::after {
        content: ' ';
        display: block;
        width: 100%;
        height: 1em;
        clear: both;
        z-index: $zIndexForeground;
    }

    .bannerCentral h1 {
        -moz-transition: all 0.7s;
        -o-transition: all 0.7s;
        -webkit-transition: all 0.7s;
        transition: all 0.7s;
    }

    &.auth .bannerCentral h1 {
        font-size: 1.6em;
        margin-top: 5px;
        margin-bottom: 2px;
    }

    &.auth .iao-wave .ia-name {
        min-width: 64px;
        min-height: 64px;
        width: 64px;
        height: 64px;
    }

    &.auth .iao-wave::after {
        height: 1px;
    }

    .iao-wave {
        pointer-events: none;

        .app-name {
            display: block;
            margin: 5px 10px 5px 10px;

            .product-name {
                font-size: 0.6em;
                display: block;
                padding-top: 5px;
            }

            .builder-name {
                transition: font-size 0.3s;
                font-size: 1.8em;
                display: block;
                font-weight: bold;

                .ia-page-icon {
                    color: #777;
                    margin-left: 0.5em;

                    > i:last-child {
                        font-size: 0.6em;
                        text-shadow: -3px 0 3px #444;
                        margin-left: -3px;
                        color: #fff;
                    }
                }
            }
        }

        .map-name {
            pointer-events: all;
        }

        .subTitle {
            display: inline-block;
            float: left;
            padding-top: 55px;
        }
    }

    .big-btns {
        /*.btn-holder {
            display: inline-block;
            margin: 5px 5px;

            > div.btn-wrap {
                display: inline-block;
                margin: 5px 5px;
            }
        }*/
        .btn {
            font-size: 32px;
            padding: 10px 25px;
            border-radius: 4px;
            color: #eee;
            font-weight: bold;
            /* box-shadow: inset 0 0 3px 1px rgba(0,0,0,0.3) !important; */
            min-width: 240px;
            border: 2px solid rgba(255, 255, 255, 0.8);
            background-color: rgba(205, 205, 205, 0.2);
        }

        .btn::before,
        .unbtn::before {
            content: '';
            display: block;
            position: absolute;
            height: 60px;
            width: 100%;
            top: -70px;
            left: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 60px;
            font-family: 'Font Awesome 5 Free', FontAwesome;
            font-size: 45px;
            font-weight: 900;
        }

        .btn-sign-in::before {
            content: '\f2bd';
            font-weight: normal; /* solid vs light */
        }

        .default-btn {
            background-color: rgba(255, 255, 255, 0.1);
            /* box-shadow: inset 0 0 6px rgba(255,255,255,0.35), inset 0 0 2px rgba(0,0,0,0.3) !important; */
            color: #fff;
            text-decoration: none;
            /* border-bottom: none; */
        }

        .default-btn:hover {
            background-color: rgba(255, 255, 255, 0.3);
            box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5), inset 0 0 2px rgba(0, 0, 0, 0.55) !important;
            text-decoration: none;
        }

        .btn-holder {
            > div {
                opacity: 0.01;
            }

            > div:nth-child(2) {
                transition: all 0.2s linear 0.1s;
            }

            > div:nth-child(1) {
                transition: all 0.4s linear 0.4s;
            }

            > div:nth-child(3) {
                transition: all 0.4s linear 0.6s;
            }
        }

        .app-desc {
            text-align: justify;
            margin-top: 10px;
            /*opacity: .65;
            transition: opacity .1s;*/
            a {
                color: $iaoActionColor;
            }
        }
    }

    .anon.landing {
        #anonStart {
            margin-bottom: 22vh;
            margin-top: 12vh;
        }

        .big-btns .btn-holder > div {
            opacity: 1;
        }

        .bannerCentral {
            height: calc(100vh - 50px);
            width: 90vw;
            left: 5vw;
            min-height: calc(100vh - 70px);
            height: auto;
            transition: height 0.3s;
        }
    }

    .tabContent .sticky {
        display: block;
        position: relative;
        max-width: 100%;
        width: 100%;
    }

    .tabContent .landing-message {
        margin-top: -10px;
        margin-bottom: 10px;
    }

    #azureWebApps .popover.sticky .arrow {
        left: 70px;
    }

    #azureGeoData .popover.sticky .arrow {
        left: 220px;
    }

    #azureIndicatorData .popover.sticky .arrow {
        left: 350px;
    }

    #azureImages .popover.sticky .arrow {
        left: 485px;
    }
    /* Signed in - smaller - need the screen estate */
    .authContent .iao-wave .iao-profiles-name::after,
    .titleContent .iao-wave .iao-profiles-name::after {
        width: 410px;
        height: 120px;
        top: 15px;
        left: 100%;
    }

    .authContent .iao-wave::after,
    .titleContent .iao-wave::after {
        height: 1px;
    }

    #userFilterSet {
        padding: 10px 0 10px 0;

        .abs,
        .btn-group {
            z-index: 200;
            font-size: 16px;
        }

        .btn-group-right {
            right: 0;
            top: 10px;
        }

        .btn-group:first-child::after {
            @extend .iao-fas;
            font-weight: bold;
            content: '\f002';
            font-weight: bold;
            display: block;
            position: absolute;
            right: 10px;
            top: 5px;
            color: #ccc;
        }
    }

    .filter-box,
    .search-box {
        transition: all 0.3s;
        display: none;
        width: auto;
        opacity: 0.7;

        &:focus {
            width: 15em;
            opacity: 1;
        }
    }

    .arc-connected {
        .search-box,
        .filter-box {
            display: block;
            float: right;
        }

        .filter-box-group {
            display: block;
            position: absolute;
            right: 30px;
            top: 10px;
            z-index: 999;
        }

        .theme-children > .filter-box-group::after {
            @extend .iao-fas;
            font-weight: bold;
            content: '\f0b0';
            display: block;
            position: absolute;
            right: 10px;
            top: 5px;
            color: #ccc;
            z-index: 10;
        }
    }

    .iaoReport,
    .arcgisWebMap,
    .arcgisCSV,
    .arcgisFeatureService {
        -moz-transition: opacity 0.4s linear;
        -o-transition: opacity 0.4s linear;
        -webkit-transition: opacity 0.4s linear;
        transition: opacity 0.4s linear;
    }

    .arcgisWebMaps .iaoReport,
    .arcgisWebMaps .arcgisWebMappingApplication,
    .arcgisWebMaps .arcgisCSV,
    .arcgisWebMaps .arcgisFeatureService,
    .iaoReports .arcgisWebMap,
    .iaoReports .arcgisCSV,
    .iaoReports .arcgisFeatureService,
    .arcgisData .iaoReport,
    .arcgisData .arcgisWebMappingApplication,
    .arcgisData .arcgisWebMap {
        opacity: 0;
        position: absolute !important;
        left: -100%;
    }

    .fa-stack .icon-bottom-layer {
        color: var(--ia-app-hi-color-light);
        top: 1px;
    }

    .fa-stack .icon-top-layer {
    }

    .fa-stack .icon-top-layer-subscript {
        bottom: -2px;
        right: -9px;
        left: auto;
    }

    .fa,
    .fas,
    .far,
    .fab {
        &.icon-top-right-superscript {
            position: absolute;
            font-size: 10px;
            top: -1px;
            right: -1px;
            left: auto;
            width: 10px !important;
            min-width: 10px !important;
            max-width: 10px !important;
        }
    }

    .ia-layers-icon {
        line-height: inherit !important;
        height: 1em !important;
        width: 1.4em;

        > i {
            -moz-transform: scale(1.1, 0.65) rotate(45deg);
            -ms-transform: scale(1.1, 0.65) rotate(45deg);
            -o-transform: scale(1.1, 0.65) rotate(45deg);
            -webkit-transform: scale(1.1, 0.65) rotate(45deg);
            transform: scale(1.1, 0.65) rotate(45deg);
            width: 100%;
        }

        > i:nth-of-type(1) {
            top: 1%;
            color: #bbb;
        }

        > i:nth-of-type(2) {
            top: -14%;
            color: var(--ia-app-hi-color-light);
            text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
        }

        > i:nth-of-type(3) {
            top: -40%;
            text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
        }
    }
    /* Apply a natural box layout model to all elements... */
    *,
    *::before,
    *::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    /* ...except those from 3rd parties that don't like it. */
    .defaultSkin table,
    .defaultSkin tbody,
    .defaultSkin a,
    .defaultSkin img,
    .defaultSkin tr,
    .defaultSkin div,
    .defaultSkin td,
    .defaultSkin iframe,
    .defaultSkin span,
    .defaultSkin *,
    .defaultSkin .mceText {
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        border-radius: $iaoBorderRadiusDef;
        padding: 2px 0 2px;
    }

    .simple-central,
    .iaoTopper {
        width: 90%;
        max-width: 1109px;
        -moz-transition: width 2s;
        -o-transition: width 2s;
        -webkit-transition: width 2s;
        transition: width 2s;
        margin-right: auto;
        margin-left: auto;
    }

    .forceWidth {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 65536px !important;
        -moz-transition: width 2s;
        -o-transition: width 2s;
        -webkit-transition: width 2s;
        transition: width 2s;
    }

    .bannerBackdrop {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: $zIndexBackground;
    }

    .bannerBackdrop::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 10px;
        background-color: rgba(74, 56, 117, 0.08);
    }

    .stripe {
        background-color: rgba(218, 214, 226, 0.9);
        border-radius: $iaoBorderRadiusDef;
        position: relative;
        padding-top: 2em;
        margin-top: 500px;
        margin-top: calc(50vh) !important;
        margin-bottom: 2em !important;
        min-height: 250px;
        min-height: calc(50vh) !important;
    }

    .stripe:target {
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .bannerCentral {
        position: relative;
        z-index: 1;
    }

    .bannerCentral .call {
        font-size: 1.4em;
        /*color: #381840;*/
    }

    .primary-action-btn,
    .bannerCentral .primary-action-btn {
        display: inline-block;
        padding: 5px 10px 5px 10px;
        border: 2px solid #381840 !important;
        color: $iaoActionColor;
        background-color: #fff;
        border-radius: $iaoBorderRadiusDef;
        font-size: 1.4em;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
    }

    .primary-action-btn:hover a,
    .bannerCentral .primary-action-btn:hover a {
        text-decoration: underline;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }

    .modal .publication-list {
        li:not(:first-child)::before {
            content: '';
            display: block;
            height: 2px;
            border-top: 1px solid #ccc;
            margin: 10px 0;
        }

        li > div,
        li > div .btn {
            font-size: 0.9em;
        }

        li > div .btn {
            float: right;
            margin-top: -5px;
        }
    }
    /* Special images for features/gallery etc. */
    img.auto-width {
        width: 300px;
        height: auto;
        -moz-transition: width 0.2s;
        -o-transition: width 0.2s;
        -webkit-transition: width 0.2s;
        transition: width 0.2s;
    }

    .auto-width-inv-r {
        margin-right: 305px;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }

    .auto-width-inv-l {
        margin-left: 305px;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }

    .bannerCentral img.auto-width {
        width: 550px;
    }

    .bannerCentral .auto-width-inv-r {
        margin-right: 495px;
    }

    .bannerCentral .auto-width-inv-l {
        margin-left: 495px;
    }

    .iaoHeading {
        color: var(--ia-app-hi-color);
    }

    .iaoLineBreak {
        height: 4px;
        background-color: var(--ia-app-hi-color);
    }

    .blurb .no-script {
        -moz-animation: slow-fade 4s;
        -o-animation: slow-fade 4s;
        -webkit-animation: slow-fade 4s;
        animation: slow-fade 4s;
        padding: 15px;
        border: 2px dotted#fff;
        border-radius: 4px;
        background-color: rgba(255, 122, 122, 0.2);
    }

    @keyframes slow-fade {
        0% {
            opacity: 0;
        }

        75% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .blurb table {
        border: 2px solid $iaoLoColor;
        border-collapse: collapse;
        min-height: 47em;
        background-color: #fff;
    }

    .blurb table td,
    .blurb table th {
        border: 2px solid $iaoLoColor;
        padding: 4px 4px 4px 4px;
    }

    .blurb table th {
        background-color: $iaoLoColor;
        color: #fff;
    }

    .blurb table td {
        vertical-align: top;
    }

    .blurb table td:first-child {
        width: 40%;
    }

    .blurb table tr.even,
    table.striped-table tr:nth-child(even) {
        background-color: #ddd;
    }

    .defaultSkin table td span {
        font-size: 0.95em;
    }

    .tm {
        font-size: 0.8em;
        letter-spacing: 0;
    }

    h1 .tm {
        font-size: 0.5em;
        display: inline-block;
        vertical-align: text-top;
        margin-top: 0.5em;
        font-weight: var(--ia-app-body-font-weight, 400);
        margin-left: 0;
    }

    .text-editor-page-mask {
        position: absolute; /* absolute not fixed because it messes with the children otherwise */
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        pointer-events: all;
        background-color: rgba(255, 255, 255, 0.2);
    }

    #textEditorContent_iaoCancelBtn img,
    #textEditorContent_iaoApplyBtn img {
        height: 16px;
        width: 16px;
        vertical-align: middle;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .defaultSkin a:hover {
        box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.7), inset -1px -1px 2px rgba(0, 0, 0, 0.5);
        border-color: #777 !important;
    }

    #textEditorContent_iaoInsertInameBtn::before,
    #textEditorContent_iaoInsertFnameBtn::before,
    #textEditorContent_iaoInsertIdateBtn::before,
    #textEditorContent_iaoInsertIvalueBtn::before {
        content: 'ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Â ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã†â€™Ãƒâ€šÃ‚Â¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã¢â‚¬Â¦Ãƒâ€šÃ‚Â¡ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¬ÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã‚Â¢ÃƒÂ¢Ã¢â‚¬Å¡Ã‚Â¬Ãƒâ€¦Ã‚Â¡ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â¢';
        padding-left: 2px;
    }

    .floatRight {
        float: right;
    }

    .floatLeft {
        float: left;
    }

    .alignRight {
        text-align: right;
    }

    .alignLeft {
        text-align: left;
    }

    .alignCenter,
    .alignCentre {
        text-align: center;
    }

    .rel {
        position: relative;
    }

    .abs {
        position: absolute;
    }

    a {
        color: #1e5673;
        text-decoration: underline;
    }

    a:visited {
        color: #ff6600;
    }

    a:hover {
        color: #99cc33;
    }

    .cb {
        clear: both;
        display: block;
    }

    .noWrap {
        white-space: nowrap;
    }

    .pad10,
    .pad-10 {
        padding: 10px;
    }

    .pad-10-hz {
        padding: 0 10px;
    }

    .smallerText {
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .settings-group {
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .tinyText {
        font-size: 11px;
    }

    .teenyText {
        font-size: 10px;
    }

    .page {
        background-color: transparent;
    }

    .cookie-grey-banner {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        height: 0;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        font-size: 13px;
        letter-spacing: 0.4px;
        transition: all 1s;
        text-align: center;
        z-index: 10001;

        > div {
            padding: 9px;
        }

        a {
            color: #fff;
        }

        button,
        button:hover {
            color: #fff;
            border: 1px solid #fff;
        }

        button {
            padding: 2px 5px 2px 5px;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: $iaoBorderRadiusDef;
            font-weight: normal;
            margin-top: -2px;
        }
    }

    .cookie-grey-banner.in {
        height: 35px;
    }

    html,
    body {
        min-height: 100%;
        /* height: 1px; */
    }

    html {
        height: 100%;
    }

    button,
    input.btn {
        cursor: pointer;
        /*padding: 3px 7px 3px 7px;
	    font-weight: 700;*/
        color: #333;
    }

    button:disabled,
    input.btn:disabled {
        color: Gray;
        opacity: 0.5;
    }
    /* Special toolbox for widgets and layout */
    .fill96 {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }

    .design-control-popout-holder {
        position: fixed;
        left: 30px;
        top: 38px;
        overflow: visible;
        width: 210px;
        min-height: calc(100vh - 38px);
        max-height: calc(100vh - 38px);
        height: auto;
        transition: all 0.3s;
        z-index: 10;
    }

    .page-layout-compact {
        .design-control-popout-holder {
            width: 75px;
            background-color: #f1f1f1;

            .widget-list h4,
            .widget-list .widget .w-name,
            .link-label,
            .toolboxTitleBar,
            li > br,
            li > br ~ span {
                display: none;
            }

            ul.menu-items {
                margin-left: 0;

                > li > a {
                    .fa,
                    .fas,
                    .fab,
                    .far {
                        font-size: 24px;
                    }
                }
            }

            .widget-list {
                .widget-btn-holder {
                    display: block !important;
                }

                .widget {
                    margin: 0 0 15px 0;
                    padding-top: 40px;
                    width: 100%;

                    &::before,
                    &::after {
                        width: 1.42em;
                        font-size: 36px;
                        margin-left: -0.71em;
                    }
                }

                div.widgetTimeSeriesTableWidget::after,
                div.widgetArcGisOnlineRelatedItemsTableWidget::after,
                div.widgetArcGisOnlineLayerAttachmentsWidget::after,
                div.widgetScatterPlotWidget::after,
                div.widgetMetadataWidget::after {
                    font-size: 18px;
                    left: 50%;
                    top: 12px;
                    color: #4a3875;
                    background-color: #f1f1f1;
                    border-radius: 18px;
                    padding: 0;
                }

                div.widgetScatterPlotWidget::after {
                    font-size: 6px;
                }
            }

            + .design-control-tab-holder {
                .design-control-popper {
                    width: 48px;
                    height: 48px;
                    font-size: 28px;
                    padding: 3px;
                    left: 42px;
                    right: auto !important;
                    margin: 0;
                    top: 65px;
                    transition: top 0.2s;

                    &.active {
                        top: 7px;
                    }
                }
            }

            .design-control-popout {
                width: 100%;
                margin-top: 0;
                transition: margin-top 0.35s;
            }

            .design-control-popout-closed {
                margin-top: 150vh;
            }

            ~ .design-column-main {
                margin-left: 120px;

                #designPageToolbar > .container-fluid > .collapse > ul.nav {
                    margin-left: 100px;
                }
            }

            .toolboxPanel {
                > div {
                    display: block !important;
                }

                > h4 {
                    display: none;
                }
            }
        }
    }

    .design-control-popout {
        width: 100%;
        height: 100%;
        position: absolute;
        -ms-transition: all 0.25s;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
        left: 0;
        overflow: hidden;
        display: block;
        border-left: 1px solid $iaoPageContrastGrey;
        border-right: 1px solid $iaoPageContrastGrey;
        border-top: 1px solid $iaoPageBackgroundGrey;
        border-bottom: 1px solid $iaoPageContrastGrey;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
        background-image: none;
        z-index: 201;
    }

    .design-control-popout-closed {
        /*display: none;*/
        width: 0;
        opacity: 0;
        /* 
        -ms-transform: scale(0,1);
        -webkit-transform: scale(0,1);
        -moz-transform: scale(0,1);
        -o-transform: scale(0,1);
        transform: scale(0,1);
        -webkit-transform: translateX(-100%) rotateY(-180deg);
        -moz-transform: translateX(-100%) rotateY(-180deg);
        -o-transform: translateX(-100%) rotateY(-180deg);
        transform: translateX(-100%) rotateY(-180deg);
	    */
    }

    .reportLabel {
        position: fixed;
        left: 0;
        top: 0;
        width: 30px;
        height: 100%;
        overflow: hidden;
        padding: 5px;
        background-color: var(--ia-app-hi-color);
        color: #fff;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 0 1px 2px rgba(255, 255, 255, 0.3);
        font-size: var(--ia-app-body-font-size, 15px);
        text-align: center;
        display: block;
        z-index: 1999;
        pointer-events: none;

        .reportLabelVert {
            position: absolute;
            left: 5px;
            top: 50px;
            white-space: nowrap;
            transform: rotate(90deg) translate(10px, -18px);
            transform-origin: 0 0;
        }

        .reportLabelText {
            display: table-cell;
            vertical-align: middle;

            div,
            div span {
                -ms-word-break: break-word;
                word-break: break-word;
            }

            div span {
                cursor: help;
                pointer-events: all;
            }
        }
    }

    .designControlCloser {
        font-size: 2em;
        font-weight: bold;
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 2px 12px 2px 12px;
        background-color: transparent;
        cursor: pointer;
        text-decoration: none;
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        color: #fff;
        opacity: 0.75;
        -moz-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    .toolboxPanel {
        /*border: 1px solid #666;
	    box-shadow: 1px 1px 2px rgba(0,0,0,0.6);
	    border-radius: 4px;
	    width: 99%;*/
    }

    .toolboxPanel .widget-btn-holder {
    }

    .design-control-popout .toolboxPanel {
        position: relative;
        width: 100%;
        height: auto;
        height: calc(100% - 90px);
        margin-top: 90px;
        text-align: center;
        z-index: 200;
    }

    .design-column-main {
        position: relative;
        margin-left: 260px;
        padding-top: 55px;
    }

    .designBox {
        line-height: normal;
        letter-spacing: normal;
        word-spacing: normal;
    }

    #designOuterHolder.active,
    .full-page-mask {
        background-color: #333;
        -moz-transition: background-color 0.7s;
        -o-transition: background-color 0.7s;
        -webkit-transition: background-color 0.7s;
        transition: background-color 0.7s;
    }

    .full-page-mask {
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10000;
    }

    #designOuterHolder.active::before {
        content: ' ';
        top: 0;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 150vh;
        background-color: #333;
    }

    .full-page-mask::before {
        content: 'InstantAtlas™ Report Builder | Starting application. Please wait...';
        color: #fff;
        font-size: 15px;
        top: 40px;
        left: 40px;
        position: fixed;
        padding-top: 140px;
        overflow: visible;
        white-space: nowrap;
        width: 120px;
        height: 120px;
        background-image: url(../images/rb4arcgis-logo192.png);
        background-repeat: no-repeat;
        background-size: 120px;
        opacity: 0.95;
        z-index: 10;
    }

    #designOuterHolder .design-column-main {
        width: auto;
    }

    #reportBox .toolboxScrollable {
        background-color: transparent;
        /* Reset font size back to the default for the browser */
        font-size: 106%;
        margin-left: 30px;
    }

    #reportTemplateChooser {
        margin-right: 10px;
        border-radius: $iaoBorderRadiusDef;
        background-color: #fff;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    }

    .profileReport .iaProfileSection,
    #selectedDesignBox .profileReport .iaProfileSection,
    #selectedDesignPreview .iaProfileSection {
        background-color: #ffffff;
        /*box-shadow: 2px 2px 3px #888888;*/
    }

    .profileReport {
        .iaProfileSection {
            border: 1px solid #cccccc;

            .arcgis-map {
                border: 1px solid #cccccc;
            }
        }
    }

    .profileReport-seamless .iaProfileSection,
    .profileReport.seamless .iaProfileSection,
    .profileReport .iaProfileSection-seamless,
    .profileReport .iaProfileSection.seamless,
    #selectedDesignBox .profileReport .iaProfileSection.seamless,
    #selectedDesignPreview .iaProfileSection.seamless {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    #selectedDesignBox .profileReport .iaProfileSection.seamless,
    #selectedDesignPreview .iaProfileSection.seamless {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .profileReport {
        margin-left: auto;
        margin-right: auto;
    }

    #designStandardHolder .profileReport {
        margin-left: 0;
    }

    #reportBox .profileReport .iaProfileSectionHeader,
    #reportBox .profileReport .flow .iaProfileSectionHeader,
    #reportBox .profileReport .iaProfileSectionFooter,
    #reportBox .profileReport .flow .iaProfileSectionFooter {
        opacity: 0.65;
    }

    #reportBox .profileReport .iaProfileSectionHeaderFooterActive,
    #reportBox .profileReport .flow .iaProfileSectionHeaderFooterActive {
        opacity: 1;
    }

    #availableWidgetsBox,
    #designTasksBox {
        overflow-y: scroll;
    }

    #availableWidgetsBox h4:first-child {
        margin-top: 0;
    }

    #designTasksBox {
        padding-bottom: 4em;
    }

    .toolboxTitleBar {
        border-bottom: 2px groove #ccc;
        font-weight: bold;
        position: absolute;
        top: 60px;
        margin-bottom: 2px;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
        width: 98%;
        left: 1%;
    }

    .navbar-default {
        background-color: darken($iaoPageBackdrop, 3.5%);
    }

    .design-control-popout {
        background-color: #fff;
    }

    .navbar-default {
        border-color: $iaoPageContrastGrey;
    }

    #designPageToolbar,
    .topNavBar {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 200;
        overflow: visible;
        text-align: left;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        /* color: #333; */
        /* background-image: -webkit-linear-gradient(top, #555 14px, #333 80px, #111); */
    }

    #designPageToolbar > .container-fluid > .collapse > ul.nav {
        margin-left: 230px;
    }

    #designPageToolbar > .container-fluid > .collapse {
        padding-right: 20px;
    }

    #designPageToolbar div.ib,
    .topNavBar div.ib {
        display: inline-block;
        margin-right: 15px;
        margin-top: 2px;
        padding: 0 8px 2px 5px;
    }

    .design-control-popout h4,
    #designPageToolbar .toolboxScrollable,
    .toolbar {
        font-size: 1em;
        padding: 7px 15px;
        text-align: left;
        font-weight: 500;
    }

    .toolboxSub a.open,
    .toolboxSub a.closed {
        text-decoration: none;
        color: inherit;
        min-width: 30px;
    }

    .widget, .pageMarker, .pageMarkerNew,
    /*.pageSave, */
    .pageViewLink {
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 60px;
        background-position: center top;
        background-repeat: no-repeat;
        /*font-variant: small-caps;*/
        /*color: #fff;*/
        cursor: default;
    }

    .pageMarker, .pageMarkerNew,
    /*.pageSave, */
    .pageViewLink {
        width: 66px;
        overflow: visible;
    }

    #availableWidgetsBox .widget,
    #designPageToolbar .pageIcon {
        filter: alpha(opacity=75);
        opacity: 0.75;
        /*color: #fff;*/
        /*font-variant: small-caps;*/
    }

    #availableWidgetsBox .widget:hover {
        /*color: #ffffff;*/
    }
    /*#designPageToolbar .pageIcon, .pageSave, .reportEditBar 
    {
	    filter: alpha(opacity=65);
	    opacity: 0.65;
	    color: #fff;
    }*/
    #designPageToolbar .pageIcon,
    .pageSave,
    #availableWidgetsBox .widget:hover,
    .reportEditBar {
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;
    }

    #availableWidgetsBox .widget:hover, #designPageToolbar .pageIcon:hover,
    /*.pageSave:hover, */
    .pageSaveNeeded, .pageSaveAnimated, .reportEditBar:hover {
        filter: alpha(opacity=100);
        opacity: 1;
    }

    .pageIcon,
    /*.pageSave, */
    .pageMarker, .pageMarkerNew, .pageViewLink {
        color: #111;
    }

    .pageIcon:hover,
    .pageSave:hover,
    #availableWidgetsBox .widget:hover {
        /*-webkit-transform: rotate(360deg);
	    -moz-transform: rotate(360deg);
	    -ms-transform: rotate(360deg);
	    transform: rotate(360deg);*/
    }

    .pageIcon,
    .pageSaveIcon {
        cursor: pointer;
        width: 66px;
    }

    .page-tile {
        .btn-holder {
            color: $standardTextColor;
        }
    }

    .page-tile-content {
        -moz-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -moz-transform: scale(0.25);
        -ms-transform: scale(0.25);
        -o-transform: scale(0.25);
        -webkit-transform: scale(0.25);
        transform: scale(0.25);
        -moz-transition: transform 0.3s;
        -o-transition: transform 0.3s;
        -webkit-transition: transform 0.3s;
        transition: transform 0.3s;
    }

    .page-tile-content.full {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        width: auto !important;
    }

    @-webkit-keyframes scale50To100 {
        0% {
            -webkit-transform: scale(0.5);
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -o-transform: scale(0.5);
            transform: scale(0.5);
        }

        50% {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
        }

        100% {
            -webkit-transform: scale(0.5);
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -o-transform: scale(0.5);
            transform: scale(0.5);
        }
    }

    @-moz-keyframes scale50To100 {
        0% {
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -o-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }

        50% {
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        100% {
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -o-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }
    }

    @-o-keyframes scale50To100 {
        0% {
            -o-transform: scale(0.5);
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }

        50% {
            -o-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        100% {
            -o-transform: scale(0.5);
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }
    }

    @keyframes scale50To100 {
        0% {
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -o-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }

        50% {
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        100% {
            -moz-transform: scale(0.5);
            -ms-transform: scale(0.5);
            -o-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
        }
    }

    .pageSaveAnimated,
    .pulseQuick {
        /*-webkit-animation: scale50To100 0.5s infinite; 
	    -moz-animation:    scale50To100 0.5s infinite; 
	    -o-animation:      scale50To100 0.5s infinite; 
	    animation:         scale50To100 0.5s infinite; */
    }

    .pageSaveAnimated {
        width: 66px;
    }

    .progress-bar {
        background-color: var(--ia-app-hi-color);
    }

    .progress-bar-holder {
        width: 100%;
        height: 100%;
    }

    .infinite-bar {
        width: 25%;
        height: 100% !important;
        -moz-animation: scroll-across 3s linear infinite;
        -o-animation: scroll-across 3s linear infinite;
        -webkit-animation: scroll-across 3s linear infinite;
        animation: scroll-across 3s linear infinite;
    }

    .infinite-bar .progress-bar {
        width: 100% !important;
        border-radius: 4px;
    }

    @keyframes scroll-across {
        100% {
            left: 99%;
            width: 25%;
        }

        50% {
            left: 25%;
            width: 50%;
        }

        0% {
            left: -24%;
            width: 25%;
        }
    }

    .grow-shrink-fade {
        -webkit-animation: grow-shrink-fade 1.5s infinite;
        -moz-animation: grow-shrink-fade 1.5s infinite;
        -o-animation: grow-shrink-fade 1.5s infinite;
        animation: grow-shrink-fade 1.5s infinite;
    }

    @keyframes grow-shrink-fade {
        0%,
        100% {
            -webkit-transform: scale(0.02);
            -moz-transform: scale(0.02);
            -ms-transform: scale(0.02);
            -o-transform: scale(0.02);
            transform: scale(0.02);
            opacity: 0.1;
        }

        50% {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes border-toggle {
        0% {
            border: 3px solid #fff;
            border-radius: 1px;
        }

        25% {
            border-left: 3px solid #fff;
            border-bottom: none;
            border-right: none;
            border-top: none;
        }

        50% {
            border-left: none;
            border-bottom: 3px solid #fff;
            border-right: none;
            border-top: none;
        }

        75% {
            border-left: none;
            border-bottom: none;
            border-right: 3px solid #fff;
            border-top: none;
        }

        100% {
            border-left: none;
            border-bottom: none;
            border-right: none;
            border-top: 3px solid #fff;
        }
    }

    @keyframes grow-shrink-fade-spin {
        0%,
        100% {
            -webkit-transform: scale(0.02) rotate(0deg);
            -moz-transform: scale(0.02) rotate(0deg);
            -ms-transform: scale(0.02) rotate(0deg);
            -o-transform: scale(0.02) rotate(0deg);
            transform: scale(0.02) rotate(0deg);
            opacity: 0.1;
        }

        50% {
            -webkit-transform: scale(1) rotate(350deg);
            -moz-transform: scale(1) rotate(350deg);
            -ms-transform: scale(1) rotate(350deg);
            -o-transform: scale(1) rotate(350deg);
            transform: scale(1) rotate(350deg);
            opacity: 1;
        }
    }

    .pageSaveNeeded a,
    .pageSaveNeeded a:hover {
        background-color: $iaoActionColor !important;
        color: #fff !important;
        border-radius: $iaoBorderRadiusDef;
    }

    .highlight-action,
    .warning-bar {
        color: $iaoActionColor !important;
    }

    .pageSaveNeeded a:hover {
        box-shadow: inset 0 0 1px 2px rgba(255, 255, 255, 0.4) !important;
    }

    .pageSaveNeeded {
        position: relative;
    }

    .dragger .widget {
        width: 66px !important;
    }

    .widget {
        background-image: none;
        -moz-background-clip: inherit;
        -webkit-background-clip: inherit;
        background-clip: inherit;
    }

    div.widget::before,
    div.widget::after {
        @extend .iao-fas;
        position: absolute;
        color: #333;
    }

    div.widget::before,
    div.widgetScatterPlotWidget::after,
    li.widgetScatterPlotWidget a::after,
    .modal-title span.widgetScatterPlotWidget::after {
        font-size: 48px;
        left: 50%;
        top: 0;
        width: 64px;
        margin-left: -32px;
        text-align: center;
    }

    .dragger,
    .dragger div {
        cursor: move;
    }

    .pageSpacer {
        height: 0.5em;
        border: none;
    }

    #designPageToolbar .dragTarget {
        height: 60px;
        border: 2px dashed #ffffff;
        border-radius: $iaoBorderRadiusDef;
        background-color: rgba(128, 128, 128, 0.5);
    }

    .wizardButtons {
        text-align: right;
        margin-right: 20px;
        padding-top: 5px;
        padding-bottom: 15px;
        box-shadow: 0 -1px 2px $iaoLoColor;
        background-color: #111111;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        border-top: 2px solid #ffffff;
    }

    .currPageBtn {
        border-radius: 2px !important;
        border: none !important;
        cursor: default;
        color: #000 !important;
    }

    .toolbar select {
        font-size: 100%;
    }

    .design-control-popout ul li a, .dropdown-menu a,
    .simpleInfoWindow a /* ArcGIS Online popup window specific */ {
        padding-top: 3px;
        background-position: 20px top;
        background-repeat: no-repeat;
        min-height: 26px;
        display: inline-block;
        text-decoration: none;
    }

    .dropdown-menu a.no-icon {
        padding-left: 3px;
    }

    .dropdown-menu hr {
        margin: 5px 0;
    }

    .design-control-popout ul li a:hover {
        text-decoration: underline;
    }

    .toolbar a:hover {
        color: #ffffff;
    }

    .rulesTabContent a.textEditLink {
        display: block;
        width: 20px;
        height: 1.1em;
        /* opacity: 0.5; */
        text-decoration: none;
        font-size: 20px;
        color: inherit;
    }

    .rulesTabContent a.textEditLink:hover {
        opacity: 1;
    }

    .rulesTabContent .ia-text-widget-content {
        margin: 15px;
    }

    .rulesTabContent .input-group .input-group-btn .btn {
        font-size: 13px;
    }

    .menuPopper {
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 20;
    }

    .menuPopper a span {
        color: inherit;
    }

    .menuPopper .popupMenu {
        position: absolute;
        top: 99%;
        right: 1px;
        display: none;
        height: auto;
        overflow: hidden;
        padding: 3px 2px 3px 2px;
        text-align: left;
    }

    .menuPopper:hover .popupMenu {
        /*display: block;*/
    }

    .dropdown-menu a,
    .dropdown-menu div:hover .subMenu,
    .dropdown-menu div:hover .subMenu a,
    .dropdown-menu div:hover .subMenu label {
        text-decoration: none;
        color: inherit;
    }

    .dropdown-menu a {
        display: block;
        width: 99%;
        padding: 1px 5px 1px 5px;
    }

    .dropdown-menu label {
        cursor: pointer;
    }

    .dropdown-menu > div {
        position: relative;
        margin: 1px 0 1px 0;
        padding: 1px 0;
        min-height: 18px;
        white-space: nowrap;
    }

    .dropdown-menu .menu-sub-item {
        padding: 3px 20px; /* Match bootstrap */
    }

    .dropdown-menu .menu-sub-item span {
        display: block;
        padding-right: 18px;
        background-image: none;
        position: relative;
    }

    .dropdown-menu .subMenu {
        font-size: 1em;
        position: absolute;
        left: 100.1%;
        top: -0.5em;
        display: none;
    }

    .dropdown-menu .menu-sub-item:hover,
    .dropdown-menu .menu-sub-item:active,
    .dropdown-menu .active .menu-sub-item {
        background-color: #f5f5f5;
    }

    .dropdown-menu > li > span.action {
        display: block;
        padding: 3px 20px;
        clear: both;
        line-height: 1.42857143;
        white-space: nowrap;
    }

    .cutItemLink,
    .copyItemLink,
    .pasteItemLink,
    .widgetRulesButton,
    .downLink,
    .upLink {
        padding-left: 18px;
        border: none;
        background-color: transparent;
        font-size: 1em;
        cursor: pointer;
    }

    .section-toggler {
        padding: 15px;
        cursor: pointer;
    }

    .section-toggler::after {
        margin-left: 5px;
    }

    h4.open,
    h4.closed,
    .toolboxPanel .open,
    .toolboxPanel .closed {
        cursor: pointer;
    }

    .tabContent h4,
    h4.open,
    h4.closed {
        padding: 5px 10px;
        background-color: $iaoBgGrey;
    }

    h4.open::after,
    h4.closed::after,
    h4 a.open,
    h4 a.closed,
    .toolboxPanel a.open,
    .section-toggler::after {
        display: block;
        float: right;
        color: inherit;
        transition: transform 0.3s;
        transform-origin: center center;
    }

    h4.open:first-of-type,
    h4.closed:first-of-type {
        border-top: none;
        margin-top: -1px;
    }

    h4.open::after,
    h4 a.open,
    .section-toggler.open::after {
        content: '\f077';
        @extend .iao-fas;
    }

    h4.closed::after,
    h4 a.closed,
    .section-toggler.closed::after {
        content: '\f077';
        @extend .iao-fas;
        transform: rotate(180deg);
    }

    ul.menu-items,
    ul.menu-itemsBtm {
        padding-left: 15px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
        border-bottom: 1px dotted #fff;
    }

    ul.menu-itemsBtm {
        margin-bottom: 0;
        border-bottom: none;
        border-top: 1px dotted #fff;
    }

    ul.noList {
        padding: 0;
        margin: 0;
    }

    ul.menu-items li,
    ul.noList li,
    ul.noDec li {
        margin-bottom: 5px;
        list-style-type: none;
        text-align: left;
    }

    ul.menu-itemsBtm li {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .menu-items a {
        text-decoration: none;
        /*border-bottom: 1px solid transparent;*/
        color: inherit;
    }

    .menu-items a:hover {
        /*border-bottom-color: #fff;*/
    }

    .icon-centred::before {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
    }

    .dropdown-menu .menu-sub-item:hover,
    .dropdown-menu .menu-item:hover,
    .dropdown-menu div .subMenu a:hover,
    .dropdown-menu div .subMenu div:hover label {
        cursor: default;
    }

    .modal .tabHolder {
        height: 33px;
    }

    .modal.ghost {
        .modal-content {
            background-color: rgba(255, 255, 255, 0.1);
        }

        *:not(:hover) {
            opacity: 0.2;
        }
    }

    .modal .custom-content,
    .modal.custom .standard-content {
        display: none;
    }

    .modal.custom .custom-content {
        display: unset;
    }

    .modal.maxi-enabled .modal-dialog,
    .modal.maxi-enabled .modal-content {
        -moz-transition: width 0.3s, height 0.3s, margin 0.3s;
        -o-transition: width 0.3s, height 0.3s, margin 0.3s;
        -webkit-transition: width 0.3s, height 0.3s, margin 0.3s;
        transition: width 0.3s, height 0.3s, margin 0.3s;
    }

    .modal {
        .btn-restore,
        .btn-maximize,
        .btn-minimize {
            display: none;
            font-size: 1em;
            margin-right: 0.5em;
            margin-top: 0.1em;
        }

        .modal-body-filler {
            margin: -15px;
        }

        .modal-header {
            &::after {
                content: ' ';
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: url(../images/Online-Banner-Background.png);
                opacity: 0.15;
                background-repeat: no-repeat;
                background-size: cover;
                z-index: 0;
            }
        }
    }

    .modal.maxi-enabled {
        .btn-maximize {
            display: unset;
        }

        .modal-content .modal-body {
            height: calc(100vh - 40px - 4.5em - 3.65em);
        }

        .modal-content .modal-body-filler {
            height: calc(100% + 30px) !important;
        }
    }

    .modal.maxi {
        .btn-restore {
            display: unset;
        }

        .btn-maximize {
            display: none;
        }

        .modal-dialog {
            margin: 2px;
            width: calc(100vw - 4px);
        }

        .modal-content {
            min-height: calc(100vh - 4px);
            max-height: calc(100vh - 4px);
        }

        .modal-content .modal-footer {
            position: absolute;
            height: 4.5em;
            bottom: 0;
            width: 100%;
        }

        .modal-content .modal-header {
            height: 3.65em;
        }

        .modal-content .modal-body {
            height: calc(100vh - 4px - 4.5em - 3.65em);
        }
    }

    .modal-body-scrollable,
    .modal-body-scrollable-xy {
        max-height: calc(100vh - 210px);
        min-height: 200px;
        overflow-y: auto;
    }

    .modal-body-scrollable.fixed,
    .modal-body-scrollable-xy.fixed {
        height: calc(100vh - 210px);

        .items {
            min-height: calc(100vh - 210px);

            > ul:empty {
                &::before {
                    content: '\f06a';
                    display: block;
                    @extend .iao-fas;
                    font-size: 1.2em;
                    position: absolute;
                    left: 1em;
                    top: -0.1em;
                    opacity: 0.8;
                }

                &::after {
                    content: 'No items found';
                    display: block;
                    opacity: 0.8;
                }
            }
        }
    }

    .modal-body-scrollable-xy {
        overflow-x: scroll;
    }

    .gallery {
        padding: 35px 15px 35px 15px;
        border-top: 1px solid $iaoPageBackgroundGrey;
    }

    .gallery-item {
        position: relative;
        border: 1px solid lighten($standardTextColor, 20%);
        border-radius: $iaoBorderRadiusDef;
        height: 12em;
    }

    .gallery-item-page,
    .gallery-item-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 10px;
        background-color: #fff;
        color: $standardTextColor;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .gallery-item-hover {
        opacity: 0;
        overflow-y: auto;
        background-color: #fff;
    }

    .gallery-item-btns {
        padding: 5px;
        margin-top: 5px;
        text-align: center;
    }

    .gallery-item-btns .btn {
        margin: 0 2px;
    }

    .gallery-item-btns .btn-default {
        cursor: default;
    }

    .gallery-item-page .profile-widget {
        background-color: lighten($standardTextColor, 50%);
        overflow: hidden;
    }

    .gallery-item-page .profile-widget::after {
        @extend .iao-fas;
        position: absolute;
        left: 5px;
        top: 0;
        content: '\f12e';
        color: lighten($standardTextColor, 40%);
        font-size: 16px;
    }

    .gallery-item:hover .gallery-item-hover {
        opacity: 1;
    }

    .gallery-description {
        margin-bottom: 20px;
    }

    .tabContent {
        border-top: 1px solid $iaoLoColor;
        border-bottom: 1px solid $iaoLoColor;
        z-index: 100;
        padding: 0 0 5px 0;
    }

    table.settings-table,
    .fileList {
        border-collapse: collapse;
        border-left: solid 1px $iaoLoColor;
        border-top: solid 1px $iaoLoColor;
        width: 100%;
        /*font-size: 0.96em;*/
    }

    .fileList {
        border-right: solid 1px $iaoLoColor;
        border-top: solid 1px $iaoLoColor;
    }

    .settings-table input,
    .settings-table select {
        font-size: 1em;
        padding: 4px 5px;
        /*height: auto;*/
    }

    .fileList,
    .fileList th {
        border-bottom: solid 1px $iaoLoColor;
    }

    table.settings-table {
        tr {
            height: 44px;
        }

        tr:hover,
        tr:focus {
            background-color: $iaoPageBackgroundGrey;
        }

        th,
        td {
            border-right: solid 1px $iaoLoColor;
            border-bottom: solid 1px $iaoLoColor;
            padding: 3px 2px 4px 2px;
            vertical-align: middle;
        }
    }

    .bg-grey {
        background-color: $iaoPageBackgroundGrey;
    }

    #widgetSettingsPopup {
        .settings-table {
            border: none;

            thead {
                display: none;
            }

            th,
            td {
                border: none;
            }

            .lab-col {
                width: 40%;
                min-width: 150px;
                padding-right: 15px;
                text-align: right;
                color: #3b5e7d;
                vertical-align: top;
                padding-top: 0.9em;
            }

            td .control-label {
                padding-top: 0;
            }
        }
    }

    .force-filter {
        .explanation {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: $iaoBorderRadiusDef;
            background-color: $iaoPageBackgroundGrey;
            margin-bottom: 1em;

            div {
                margin-left: 32px;
            }

            div::before {
                content: '\f05a';
                @extend .iao-fas;
                font-size: 24px;
                position: absolute;
                left: -32px;
                opacity: 0.8;
            }

            p:last-child {
                position: absolute;
                right: 5px;
                top: 5px;
            }
        }

        .settings-group {
            display: block !important;
        }

        h4,
        .widget-settings-toggler {
            display: none !important;
        }

        tr {
            display: none;
        }

        tr.marked {
            display: table-row !important;

            & > td.lab-col::before {
                content: ' ';
                position: absolute;
                left: 2px;
                top: 0;
                display: block;
                font-size: 0.9em;
                color: #777;
                pointer-events: none;
                transform: rotate(90deg) translate(0, -1.2em);
                min-width: 44px;
                height: 1.5em;
                transform-origin: 0 0;
                overflow: hidden;
                background-color: #fff;
                padding: 1px 3px;
                border-bottom: 1px solid #efefef;
                border-top: 1px solid #efefef;
                text-align: left;
                font-weight: 600;
            }

            &.marked-first {
                & > td.lab-col::before {
                    content: attr(data-group);
                    z-index: 3;
                    border-left: 1px solid #efefef;
                }

                &:hover > td.lab-col::before {
                    z-index: 9;
                }
            }
        }

        .settings-group:nth-of-type(even) tr.marked > td.lab-col::before {
            background-color: #efefef;
        }
    }

    .help-hov,
    .dbTextPopper,
    .dbTextDropper {
        cursor: pointer;
        opacity: 0;
        position: relative;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
    }

    .help-hov {
        font-size: var(--ia-app-body-font-size, 15px);
        width: 18px;
        height: 18px;
        margin-left: 5px;
        display: inline-block;
    }

    .dbTextDropper,
    .dbTextPopper {
        opacity: 0.65;
    }

    .input-group {
        .dbTextDropper,
        .dbTextPopper {
            opacity: 1;
        }
    }

    .lab-col .help-hov {
        position: absolute;
        left: 5px;
        top: 6px;
        z-index: 10;
    }

    .help-hov span {
        display: none;
    }

    .help-hov::before {
        position: absolute;
        top: 5px;
        left: 0;
    }

    .settings-table .lab-col:hover .help-hov,
    .settings-table td:hover .dbTextPopper,
    .settings-table td:hover .dbTextDropper,
    .i-item:hover .help-hov {
        opacity: 0.9;
    }

    .widgetEditBar,
    .reportEditBar .popout {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 2px 2px 2px 2px;
        border-radius: $iaoBorderRadiusDef;
        display: none;
        z-index: 10;
        background-color: #dfdfe0;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        color: #ffffff;
    }

    .anchor-marker {
        position: absolute;
        background-color: #dfdfe0;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        border-radius: $iaoBorderRadiusDef;
        width: 20px;
        height: 20px;
        font-size: 16px;
        overflow: hidden;
    }

    .child-anchor {
        background-color: rgba(150, 150, 150, 0.1);
    }

    .active-widget-parent {
        background-color: rgba(228, 228, 228, 0.2);
    }

    .active-widget-parent::before {
        content: ' ';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        border: 1px dotted rgba(128, 128, 128, 0.5) !important;
    }

    .active-widget-parent::after {
        position: absolute;
        right: 3px;
        top: 3px;
        @extend .iao-fas;
        font-size: 22px;
        content: '\f0a5';
        color: rgba(128, 128, 128, 0.5);
    }

    .dataExplore,
    .dataDelete,
    .dataEditButton,
    .widgetDeleteButton,
    .widgetCutButton,
    .widgetEditButton,
    .widgetDownButton,
    .widgetUpButton,
    .publicationDelete,
    .publicationExplore,
    .publicationLock,
    .publicationUnlock,
    .designDelete,
    .designUse,
    .dataDownload {
        background-position: left top;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        text-decoration: none;
        display: inline-block;
        filter: alpha(opacity=50);
        opacity: 0.5;
        color: #666666;
        overflow: hidden;
    }

    .widgetEditButton {
        width: 25px;
        height: 25px;
    }

    .widgetEditButton span {
        /*width: 20px;
	    padding-left: 10px;
	    font-size: var(--ia-app-body-font-size-small, 13px);*/
    }
    /* Rules related starts */
    .rulesShortcuts {
        padding-bottom: 5px;
        border-bottom: 1px solid #cccccc;
    }

    .rulesAdvanced {
        padding-bottom: 5px;
        border-bottom: 1px solid #cccccc;
        display: none;
    }

    .rulesTypeBox {
        float: left;
        height: auto;
        padding: 1px 5px 0 15px;
        border: 1px solid #999999;
        border-radius: $iaoBorderRadiusDef;
        margin-right: 10px;
        background-color: $iaoPageBackgroundGrey;
        cursor: pointer;
    }

    .rulesTypeSelected {
        background-color: #cccccc;
        color: #000000;
        min-height: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #999999;
        cursor: default;
        float: none;
    }

    .rulesToggleAdvanced,
    .rulesEditTrigger {
        background-color: #cccccc;
        padding: 2px 3px 5px 3px;
        border: 1px solid #cccccc;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        position: absolute;
        right: 0;
        top: 60px;
        z-index: $zIndexForeground;
        box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .rulesToggleAdvanced {
        padding: 5px 5px 0 5px;
        top: 0;
        width: 140px;
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .no-gutter > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }

    #textRulesPopup .settingsTab {
        border-bottom: 2px solid #000000;
        padding-bottom: 0;
    }

    #textRulesPopup .selectedTab {
        border-bottom: 2px solid #ffffff;
    }

    #textRulesPopup .tabScrollerContainer {
        position: relative;
    }

    #textRulesPopup .tabScrollerContainer img {
        padding: 2px;
        background-color: #ffffff;
        border: 1px solid #aaaaaa;
    }

    #textRulesTabs {
        padding-right: 20px;
        padding-left: 20px;
    }

    #textRulesPopup .tabScrollButton {
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        padding: 2px;
        /*background-color:#ffffff;
	    border:1px solid #aaaaaa;*/
    }

    #textRulesPopup .forwardScroll,
    #textRulesPopup .backScroll {
        position: absolute;
        top: 4px;
        opacity: 0.5;
    }

    #textRulesPopup .forwardScroll {
        right: -15px;
    }

    #textRulesPopup .backScroll {
        left: -15px;
    }

    #textRulesPopup .forwardScroll:hover,
    #textRulesPopup .backScroll:hover {
        opacity: 0.9;
    }
    /* Rules related ends */
    .fileList th {
        border-right: solid 1px $iaoLoColor;
        padding: 2px 2px 2px 2px;
        background-image: none;
        border-radius: 0;
        box-shadow: none;
    }

    .fileList td {
        padding: 2px 2px 2px 2px;
    }

    .fileList td.first {
        border-right: dotted 1px #dddddd;
    }

    .fileList td label {
        width: 90%;
        display: inline-block;
    }

    .settings-table th,
    .fileList th {
        background-color: #999999;
        color: #ffffff;
    }

    .settingsTab {
        background-color: $iaoPageBackgroundGrey;
        color: $standardTextColor;
        float: left;
        margin-left: 10px;
        border: 1px solid $iaoLoColor;
        height: 35px;
        padding: 2px 10px 2px 10px;
        z-index: 99;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 2px;
        position: relative;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        font-size: 1.2em;
    }

    .settingsTab label,
    .settingsTab input {
        cursor: pointer;
    }

    .settingsTab a {
        text-decoration: none;
        color: inherit;
    }

    .modal-body .settingsTab {
        border-top-color: rgba(155, 155, 155, 0.7);
        border-left-color: rgba(155, 155, 155, 0.7);
        border-right-color: rgba(155, 155, 155, 0.7);
        box-shadow: inset 1px 1px 4px rgba(90, 90, 90, 0.2);
        padding: 2px 8px 2px 8px;
    }

    .selectedTab,
    .bigTabs .selectedTab,
    .bigTabs .selectedTab:hover,
    .standardTabs .selectedTab,
    .bigTabs .standardTabs .selectedTab {
        background-color: #ffffff;
        border-bottom: 2px solid #ffffff;
        border-bottom-color: #ffffff;
        z-index: 101;
        color: #444444;
    }

    .toolboxScrollable {
        position: relative;
        padding: 5px 5px 5px 5px;
        height: 20em;
        overflow-y: scroll;
        /*overflow-x: hidden;*/
        background-color: #ffffff;
    }

    .theme-children .toolboxScrollable {
        min-height: calc(100vh - 250px);
        padding-left: 30px;
    }

    #userContent {
        min-height: calc(100vh - 230px) !important;

        > .dataset {
            padding: 15px 5px 5px 5px;
        }

        .theme-children.in ~ .dataset {
            opacity: 0.1;
            pointer-events: none;
        }
    }

    .modal-title {
        margin-right: 20px;
    }
    /* Experiment - might not use it */
    /*.modal-header {
	    background-color: lighten($iaoLoColor,10%);
	    border: 1px solid #fff;
	    color: #fff;
	    border-radius: 5px 5px 0 0;
    }

    .modal-header .close, .modal-header .close:hover {
	    color: #fff;
	    opacity: 1;
    }*/
    #indicatorQuickTools {
        bottom: 5px;
        right: 35px;
        z-index: 9999;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 2px 0;
        border-top-left-radius: 2px;
    }

    #indicatorsSettingsSection {
        margin-bottom: 3em;
    }

    .modal {
        .widget-settings-toggler {
            height: auto;
            position: absolute;
            bottom: -3em;
            left: 15px;
            z-index: 1000;
            text-align: right;
        }
        /*.indicator-search-panel {
            position: absolute;
            bottom: -48px;
            left: 20px;
            z-index: 9999;
        }*/
        .modal-dialog.modal-expandable {
            transition: width 0.1s, height 0.1s, left 0.2s, right 0.2s;

            .modal-header {
                z-index: 1001;

                .modal-ui-controls {
                    font-size: 0.8em;
                    margin-right: 5px;
                    margin-top: 2px;
                    color: #888;
                    background-color: #fff;
                    border-left: 6px solid #fff;
                    border-top: 4px solid #fff;
                    border-bottom: 4px solid #fff;
                }

                .modal-ui-controls a {
                    display: inline-block;
                    margin-right: 3px;
                }
            }
        }
    }

    .modal-content {
        .toolboxScrollable {
            min-height: 5em;
            height: 22em;
            height: 50vh;
        }
    }

    body.modal-dock-right-open {
        #designPageToolbar > .container-fluid > .collapse > ul.nav.navbar-right {
            margin-left: 0;
            margin-right: 290px;
            transition: all.3s;
        }
    }

    .modal-lg {
        .modal-content {
            .toolboxScrollable {
                height: calc(100vh - 60px - 14em);
            }

            #widgetTipsGroup:not(.iao-hidden) ~ .toolboxScrollable {
                height: calc(100vh - 60px - 14em - 74px);
            }
        }
    }

    .modal-body.modal-body-tabbed {
        padding: 15px 0 0 0;
    }

    .yScrollOnly {
        /*overflow-x: hidden;*/
    }

    .modal-body .toolboxScrollable {
        min-width: 200px;
        width: auto;
        z-index: 100;
        margin: 0;
    }

    .modal-body .scrollCancel {
        width: auto;
    }

    #widgetSettingsPopup .toolboxScrollable,
    #sectionSettingsPopup .toolboxScrollable,
    #reportSpacingPopup .toolboxScrollable,
    #fontSettingsPopup .toolboxScrollable {
        padding: 10px 0;
    }

    .modal-body .toolboxScrollable div.spaced {
        margin-left: 5px;
        margin-right: 5px;
    }

    .scrollCancel {
        overflow: hidden;
    }

    .modal .buttonHolder button,
    .modal .buttonHolder .btn {
        min-width: 100px;
        max-width: 40%;
    }

    .popupDialogMessage {
        padding: 0 0 0 0;
        /*color: $iaoLoColor;*/
    }

    .popupDialogMessageContent {
        padding: 0 0 0 0;
    }

    .modal .tabContent {
        position: relative;
        background-color: #fff;
        border-top-color: rgba(155, 155, 155, 0.7);
        padding-top: 5px;
        border-bottom: none;
    }

    .modal .buttonHolder {
        padding: 10px 0 10px 0;
        background-color: rgba(255, 255, 255, 0.95);
        border-top: 1px solid #ccc;
    }

    .close-dialog-btn,
    .close-dialog-btn:hover {
        width: 16px;
        height: 16px;
        border: none;
        content: ' ';
        text-decoration: none;
    }

    .theme-children .close-dialog-btn {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 24px;
        height: 24px;
        color: #222;
        padding: 4px;
        border-radius: 4px;
        text-align: center;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        text-decoration: none;
        z-index: 100;
    }

    .theme-children .close-dialog-btn:hover,
    .theme-children .close-dialog-btn:focus {
        background-color: white;
        text-decoration: none;
    }
    /*.close-dialog-btn::before 
    {
	    content: "\e71c";
    }*/
    .popupDialogTitleBar a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
    }

    .popupDialogTitleBar a:hover::before {
        color: rgba(255, 255, 255, 0.99);
    }

    .templateDesc .close-dialog-btn:hover::before,
    .theme-children .close-dialog-btn:hover::before {
        color: rgba(0, 0, 0, 0.9);
    }

    .deletion-active,
    .dataset.deletion-active {
        border-width: 2px;
        border-color: #ff1111;
    }

    .dataset.deletion-active {
        background-color: rgba(255, 17, 17, 0.5);
    }

    .dataset.deletion-commit {
        opacity: 0.5;
    }

    .deletion-active .widget-activeBody {
        background-color: #ff1111;
        opacity: 0.5;
    }

    .sizerHandle {
        position: absolute;
        border: solid 1px #999999;
        background-color: rgba(240, 240, 240, 0.9);
        width: 10px;
        height: 10px;
        z-index: 10;
    }

    .sizerHandle.n {
        top: -5px;
    }

    .sizerHandle.s {
        bottom: -5px;
    }

    .sizerHandle.e {
        right: -5px;
    }

    .sizerHandle.w {
        left: -5px;
    }

    .sizerHandle.c {
        left: 50%;
        margin-left: -5px;
    }

    .sizerHandle.m {
        top: 50%;
        margin-top: -5px;
    }

    body.touched .sizerHandle {
        width: 20px;
        height: 20px;
    }
    /* Dialog support */
    .warningBig,
    .questionBig,
    .informationBig,
    .successBig,
    .forbiddenBig,
    .waitingBig,
    .unknownBig,
    .errorBig {
        padding-left: 60px !important;
        min-height: 53px;
        position: relative;
    }

    .warningBig::before,
    .questionBig::before,
    .informationBig::before,
    .successBig::before,
    .forbiddenBig::before,
    .waitingBig::before,
    .unknownBig::before,
    .errorBig::before {
        position: absolute;
        width: 48px;
        height: 48px;
        left: 0;
        top: -5px;
        @extend .iao-fas;
        font-size: 48px;
    }

    .warningBig::before {
        content: '\f071';
    }

    .questionBig::before {
        content: '\f059';
    }

    .informationBig::before {
        content: '\f05a';
    }

    .successBig::before {
        content: '\f14a';
    }

    .forbiddenBig::before {
        content: '\f056';
    }

    .waitingBig::before {
        content: '\f0ae';
    }

    .unknownBig::before {
        content: '\f059';
    }

    .errorBig::before {
        content: '\f057';
    }

    .label20 {
        float: left;
        width: 20%;
        padding-top: 2px;
        padding-right: 15px;
        text-align: right;
    }

    .label10 {
        float: left;
        width: 10%;
        max-width: 10%;
        min-width: 10%;
        padding-top: 2px;
        text-align: right;
    }

    .label20-partner {
        float: left;
        width: 76%;
        padding-top: 2px;
        text-align: left;
    }

    .label20-partner input,
    .label20-partner select,
    .label20-partner textarea {
        margin: 0;
    }

    .label20 ~ input,
    .label20 ~ select,
    .label20 ~ textarea {
        max-width: 79%;
    }

    .label20 ~ select {
        padding: 3px 0;
    }

    .fill73 {
        width: 73%;
    }

    .label20,
    .popupDialogMessage label,
    fieldset legend {
        color: #111;
        font-weight: var(--ia-app-body-font-weight, 400);
    }

    .popupDialogMessage label {
        max-width: 90%;
    }

    .indent1 {
        margin-left: 30px;
    }

    .wptype_color {
        cursor: pointer;
        width: 2em !important;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0) !important;
        padding: 0 5px;
        text-align: center;
        font-size: var(--ia-app-body-font-size-small, 13px) !important;
        height: 2em !important;
        -moz-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    .settings-table td,
    .settings-table .row > *[class^='col-'] {
        > .wptype_text,
        > .wptype_uri,
        > .input-group {
            width: 99%;
        }

        .wptype_pixel {
            width: 4.5em;
            margin-right: 2px;
            padding-right: 2px;
        }
    }

    .exportControls {
        position: absolute;
        top: 5px;
        right: 25px;
        padding: 5px 5px 1px 5px;
        background-color: rgba(255, 255, 255, 0.95);
        border: 1px solid #333;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        opacity: 0.3;
        font-size: 32px;
    }

    .exportControls:hover,
    .exportControls:focus {
        opacity: 0.9;
        z-index: 1000;
    }

    .exportControls a {
        text-decoration: none;
        color: $standardTextColor;
        display: block;
        margin-bottom: 5px;
        width: 32px;
        height: 32px;
    }

    .exportControls a:hover {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
        color: #333;
    }

    #exportToPdfLink {
        display: none !important; /* disabled for now */
    }

    .image-popper,
    .image-popper-trigger {
        cursor: pointer;
    }

    .image-popper {
        border: 1px solid #aaa;
        border-radius: $iaoBorderRadiusDef;
        padding: 5px 5px 5px 5px;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        /* Moved out of script and into here, also not absolute but fixed */
        position: fixed;
        width: auto;
        height: auto;
        left: -1000px;
        top: -1000px;
        padding: 0;
        transition: top 0.3s;
    }

    #indexMapBox {
        margin-left: auto;
        margin-right: auto;
    }

    .arcgis-map {
        width: 80%;
        height: auto;
        margin: 10px auto 10px auto;
        min-height: 400px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: $iaoBorderRadiusDef;
        /*box-shadow: 1px 1px 2px rgba(0,0,0,0.3);*/
    }

    .arc-search-results {
        margin: 30px;
        padding: 0;
        font-size: 1.25em;
    }

    .arc-search-results li {
        list-style: none;
        margin: 10px;
    }

    .mapTooltipX {
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.75);
        color: rgba(255, 255, 255, 1);
        background-color: #111;
        font-size: 1em;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        padding: 2px 5px 2px 5px;
        z-index: 2000;
    }

    .scroller {
        text-align: center;
    }

    .scroller .item {
        display: inline-block;
        width: 100px;
        height: 80px;
        margin: 5px;
        vertical-align: middle;
        overflow: hidden;
        -ms-word-wrap: break-word;
        word-wrap: break-word; // legacy name
        -ms-word-break: break-word;
        word-break: break-word;
        overflow-wrap: break-word; // new name as per the CSS3 spec
    }

    .scroller .item button {
        width: 100%;
        height: 80px;
    }

    #iaWidgetSizeFiller.iaoProgress {
        background-color: #ffffff;
        filter: alpha(opacity=75);
        opacity: 0.75;
    }

    .iaoSizer {
        right: 1px;
        bottom: 1px;
        cursor: se-resize;
        width: 16px;
        height: 16px;
        border-top-left-radius: 24px;
    }

    div.spaced {
        margin-top: 0.75em;
        margin-bottom: 0.75em;
    }

    div.smSpaced {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .colorSwatches li {
        position: relative;
        float: left;
        display: inline-block;
        width: 80px;
        height: 80px;
        margin: 2px;
        font-size: 1em;
        text-align: center;
    }

    .colorSwatches li input {
        position: absolute;
        left: 16px;
        top: 5px;
        cursor: pointer;
    }

    .colorSwatches li label {
        cursor: pointer;
    }

    .palette-colors {
        margin-top: 2px;
    }

    .palette-colors {
        .swatch {
            width: 1em;
            height: 1em;
            min-width: 8px;
            min-height: 8px;
            display: inline-block;
            border-radius: 0;
            border: none;
            margin-right: 2px !important;
            cursor: default;
        }
    }

    .input-group {
        & > .palette-colors {
            display: flex;
            flex-direction: row;
            margin-top: 0;
            width: 100%;

            & ~ .form-control {
                width: 0;
                height: 0;
                padding: 0;
                display: none;
                transition: width 0.4s, opacity 0.4s;
                opacity: 0;
                z-index: 100;
            }

            > .swatch {
                flex: 1 1;
                height: 100%;
                width: auto;
                min-width: 2px;
                cursor: pointer;
            }

            ~ .input-group-btn.btn-float {
                width: 0 !important;
                padding: 0;

                > .btn {
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    padding: 0;
                    background-color: transparent;
                    font-size: 0.8em;
                    opacity: 0.5;
                    z-index: 1000;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        &.raw {
            & > .palette-colors ~ .form-control {
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 1;
            }

            & > .palette-colors ~ .input-group-btn.btn-float {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 101;
            }
        }
    }

    .swatchName {
        padding: 0;
        margin: 0;
    }

    .mapColourRanges .swatch {
        display: inline-block;
        margin: 1px;
        width: 24px;
        height: 24px;
        cursor: default;
    }

    div.autoCenter {
        display: inline-block;
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .padIconButton {
        padding: 3px 7px 3px 25px !important;
        background-position: 4px center;
    }

    .pageBackdrop {
        display: none;
        position: fixed;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: $zIndexBackground;
        -moz-opacity: 0.25;
        -ms-opacity: 0.25;
        -webkit-opacity: 0.25;
        opacity: 0.25;
    }

    .scale-1,
    .scale-2,
    .scale-3,
    .scale-4,
    .scale-5 {
        position: absolute;
        bottom: 0;
        right: 0;
        -moz-transition: all 0.6s;
        -o-transition: all 0.6s;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
    }

    .bannerCentral img.scale-1 {
        width: 130px;
        right: 225px;
        z-index: 3;
    }

    .bannerCentral img.scale-2 {
        width: 150px;
        z-index: 3;
        right: 305px;
    }

    .bannerCentral img.scale-3 {
        width: 350px;
        z-index: $zIndexForeground;
    }

    .pageBackdrop img.scale-1 {
        width: 430px;
        right: 475px;
        bottom: -250px;
        z-index: 3;
    }

    .pageBackdrop img.scale-2 {
        width: 450px;
        z-index: 3;
        left: 25px;
        bottom: -250px;
    }

    .pageBackdrop img.scale-3 {
        width: 450px;
        bottom: -250px;
        z-index: $zIndexForeground;
    }

    .pageBackdrop img.scale-4 {
        width: 450px;
        right: 200px;
        bottom: -350px;
        z-index: $zIndexForeground;
    }

    .pageBackdrop img.scale-5 {
        width: 450px;
        right: 700px;
        bottom: -350px;
        z-index: 4;
    }

    .bannerCentral .esri-partner-logo {
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 0 0 0 0 0;
        z-index: 10;
    }

    .bannerCentral .esri-partner-logo img {
        width: 36px;
    }

    .dataset {
        background-color: #fff;
    }
    /* Specials for sub-themes etc in dialogs start */
    .dataset,
    .datasetTheme,
    .publication,
    .design,
    .layout,
    .layoutCollection,
    .colourProfile,
    .reportCollection {
        position: relative;
        /*float: left;*/
        /*box-shadow: 1px 1px 4px rgba(0,0,0,0.3);*/
        margin: 10px;
        padding: 5px 5px 5px 5px;
        min-width: 100px;
        width: 140px;
        min-height: 140px;
        height: 140px;
        display: inline-block;
        z-index: 99;
        border-radius: 0;
        border: 1px solid $iaoBorderGrey;
        /*cursor: pointer;*/
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        vertical-align: top;
    }

    .dataset {
        .item-modified {
            display: none;
        }

        .thumbnail-holder {
            height: 60px;
            overflow: hidden;

            .fa,
            .fas,
            .far {
                font-size: 48px;
                margin-top: 5px;
            }
        }

        .thumbnail-image {
            width: 80px;

            &.thumbnail-std-image {
                width: auto;
                height: 60px;
                border: none;
            }
        }

        &.item-collection-group {
            .thumbnail-holder {
                height: 60px;
            }
        }
    }

    .dataset.item-collection-group .thumbnail-image,
    .tiny-thumbnail {
        width: 50px;
        opacity: 0.7;
        filter: grayscale(99%);
    }

    .tiny-thumbnail {
        display: inline-block;
        margin: -4px 9px 0 4px;
        max-width: 40px;
        width: 40px;
        border: none;
    }

    .dataset.item-collection-group:hover .thumbnail-image,
    label:hover .tiny-thumbnail {
        opacity: 1;
    }

    .arc-connected .big-tiles .dataset.item-collection-group .thumbnail-image {
        display: block;
        height: 80%;
        width: auto;
        margin: 0 auto;
    }

    @import 'defaults-list-layouts.scss';

    .item-collection-Locked {
        opacity: 0.4;
        background-color: #ddd;
        cursor: default;
    }

    .modal-body-scrollable .webItem {
        cursor: pointer;
    }

    .webItem a img {
        border: 1px solid #aaa;
        border-radius: $iaoBorderRadiusDef;
    }

    .webItem div.text-center {
        display: block;
    }

    .csvList .webItem {
        cursor: pointer;
    }

    .csvList .webItem .dsName {
        bottom: 1px;
        top: auto;
        max-height: 90px;
    }

    .geo-list {
        /*margin-top: .5em;
        padding: 0;*/
    }

    .geo-list li {
        list-style: none;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .geo-list li::before {
        @extend .iao-fas;
        content: '\f0ac';
        margin-right: 5px;
        font-size: 1.25em;
    }

    .landingBoxLinks > .dataset {
        float: none;
    }

    .theme-children {
        border: 1px solid $iaoBorderGrey;
        border-radius: 0;
        background-color: #ffffff;
        z-index: 100;
        padding-left: 2.5em;
        padding-bottom: 0;
        /*box-shadow: 1px 1px 3px rgba(0,0,0,0.5);*/
        text-align: left;

        .toolboxScrollable {
            background-color: #fff;
        }
    }

    .simple-central {
        .dataset.active {
            .iao-icon.fa-folder::before {
                content: '\f07c';
            }
        }

        .dataset.item-collection-search-results,
        .item-collection-search-results-container {
            .iao-icon.fa-folder::before,
            .themeSidebar .iao-icon > .fa-folder-open::before {
                content: '\f002';
                font-weight: bold;
            }

            .themeSidebar .sidebarTitle > p > span::after {
                content: '(' attr(data-query-text) ', ' attr(data-query-result-count) ' matches)';
                font-size: 0.9em;
                margin-left: 5px;
                font-style: italic;
            }

            .themeSidebar .sidebarTitle a.refresh {
                display: none;
            }
        }

        .theme-children {
            /* Tiny and hidden by default */
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 0;
            overflow: hidden;
            opacity: 0;
            transition: all 0.3s;
        }

        .theme-children.item-collection-search-results-container {
            z-index: 1001;
        }

        .theme-children.in {
            width: 100%;
            height: calc(100vh - 220px);
            opacity: 1;

            .toolboxScrollable {
                height: 100%;
            }
        }

        .themeSidebar {
            position: absolute;
            left: 0;
            top: 0;
            width: 2.9em;
            height: 100%;
            background-color: $iaoBgGrey;
            color: $standardTextColor;
            margin-left: 0 !important;
        }

        .themeSidebar p {
            -webkit-transform: rotate(90deg); /* Chrome+ */
            -moz-transform: rotate(90deg); /* Firefox */
            -o-transform: rotate(90deg); /* Opera */
            -ms-transform: rotate(90deg);
            transform: rotate(90deg); /* IE10+ */
            white-space: nowrap;
            display: block;
            /*font-variant: small-caps;*/
            padding: 5px;
            margin: 5px 12px 0 8px;
        }

        .themeSidebar .btnClose {
            text-align: center;
            position: relative;
            width: auto;
            margin-left: auto;
            margin-top: 5px;
            margin-right: 0.5em;
        }

        .themeSidebar .siderbarTitle {
            margin-top: 2em;
        }

        .themeSidebar .refresh {
            margin-left: 10px;
        }
    }

    .designName,
    .dsName,
    .publicationName,
    .layoutName,
    .cpName {
        margin-top: 2px;
        /*border: 2px solid #39342d;
	    background-color: #655c4f;*/
        border-radius: 0;
        /*color: #ffffff;
	    /*font-variant: small-caps;*/
        padding: 5px;
        height: 70px;
        overflow: hidden;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        text-align: center;
        /*box-shadow: inset -1px -1px 3px rgba(0, 0, 0, 0.4), inset 1px 1px 3px rgba(255, 255, 255, 0.2);*/
        position: absolute;
        top: 65px;
        left: 1%;
        width: 98%;
    }

    #widgetQuickSettings .dsName {
        top: 0;
        height: auto;
        background-color: rgba(255, 255, 255, 0.9);
        width: 96%;
        left: 2%;
        border-radius: 0;
        font-weight: 600;
        color: #555;
    }

    #widgetQuickSettings .dataset {
        margin: 1%;
        width: 48%;
        min-height: 160px;
        background-color: #fff;
    }

    .namePad {
        padding-left: 28px;
        padding-right: 20px;
    }
    /* Specials for sub-themes etc in dialogs end */
    .forceWhite,
    .forceWhite label,
    .forceWhite a {
        color: #fff;
    }

    .iasMask,
    .iaoMask,
    .iao-mask {
        background-color: rgba(255, 255, 255, 0.45);
    }

    .mask-label {
        .expando {
            letter-spacing: 0.5px;
            padding: 5px 10px;
            background-color: rgba(255, 255, 255, 0.8);
            font-size: 12px;
        }
    }

    .link-disabled,
    .btnDisabled {
        color: #999;
        -moz-opacity: 0.5;
        opacity: 0.5;
        cursor: default;
    }

    .link-disabled:hover,
    .btnDisabled:hover {
        background-color: transparent !important;
    }
    /* Custom radio buttons (mainly because Chrome is messed up when using CSS3) */
    input[type='checkbox']:not(.native),
    input[type='radio'] {
        visibility: hidden;
        position: absolute;
    }

    input[type='checkbox']:not(.native) + label,
    input[type='radio'] + label,
    .checkbox input[type='checkbox']:not(.native) ~ label,
    .checkbox input[type='radio'] ~ label {
        cursor: pointer;
        margin-left: 18px;
    }

    .igroup-name input[type='checkbox']:not(.native) + label {
        margin-left: 20px;
    }

    input[type='checkbox']:not(.native):disabled + label,
    input[type='radio']:disabled + label,
    .checkbox input[type='checkbox']:not(.native):disabled ~ label,
    .checkbox input[type='radio']:disabled ~ label,
    .label20.disabled,
    .popupDialogMessage label.disabled,
    fieldset legend.disabled {
        cursor: default;
        color: #999;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .ind input[type='checkbox']:not(.native):disabled + label,
    .ind input[type='checkbox']:not(.native):disabled + label::before {
        font-size: 9px;
        margin-bottom: 0;
    }

    .search-found {
        background-color: fade(var(--ia-app-hi-color), 20%);
        box-shadow: -3px 0 0 0 fade(var(--ia-app-hi-color), 20%), 3px 0 0 0 fade(var(--ia-app-hi-color), 20%);
        border-radius: $iaoBorderRadiusDef;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }

    .search-found-current {
        background-color: fade(var(--ia-app-hi-color), 60%);
        box-shadow: -3px 0 0 0 fade(var(--ia-app-hi-color), 60%), 3px 0 0 0 fade(var(--ia-app-hi-color), 60%);
        color: var(--ia-app-hi-color-negative, #fff);
    }

    input[type='checkbox']:not(.native) + label::before,
    input[type='radio'] + label::before,
    .checkbox input[type='checkbox']:not(.native) ~ label::before,
    .checkbox input[type='radio'] ~ label::before {
        @extend .iao-fas;
        /*content: '\f096';*/
        content: '\f0c8';
        display: inline-block;
        height: 19px;
        margin: -4px 4px 0 -19px;
        vertical-align: middle;
        width: 19px;
        font-size: 16px;
        font-weight: normal;
    }

    input[type='checkbox']:not(.native) ~ ul.ind-specific-dates,
    input[type='radio'] ~ ul.ind-specific-dates {
        height: 0;
        overflow: hidden;
        opacity: 0;
        font-size: 0.95em;
        -moz-transition: height 0.6s, opacity 0.9s;
        -o-transition: height 0.6s, opacity 0.9s;
        -webkit-transition: height 0.6s, opacity 0.9s;
        transition: height 0.6s, opacity 0.9s;
    }

    input[type='checkbox']:not(.native):checked ~ ul.ind-specific-dates,
    input[type='radio']:checked ~ ul.ind-specific-dates {
        height: auto;
        opacity: 1;
    }

    input[type='checkbox']:not(.native):checked ~ ul.ind-specific-dates::before {
        content: ' or: ';
        display: block;
        padding: 2px 0;
    }

    .same-size {
        input[type='checkbox']:not(.native) + label::before,
        input[type='radio'] + label::before,
        .checkbox input[type='checkbox']:not(.native) ~ label::before,
        .checkbox input[type='radio'] ~ label::before {
            font-size: 1em;
            height: 1em;
            width: 1.4em;
        }
    }

    .ind-specific-dates li.ind {
        display: inline-block;
        margin-right: 10px;
    }

    .ind-specific-dates li.ind::after {
        content: ',';
    }

    .ind-specific-dates li.ind:last-of-type::after {
        content: '';
    }

    input[type='checkbox']:not(.native) + label.slider {
        text-align: left;
    }

    input[type='checkbox']:not(.native) + label.slider::before {
        content: '\f204'; /*'\f0c8';*/
        font-weight: bold;
        margin-top: 0;
    }

    .colorSwatches li input[type='radio'] + label::before {
        display: block;
        margin: 0;
        right: 19px;
        top: 11px;
        position: absolute;
        width: 19px;
    }

    input[type='checkbox']:not(.native) + label::before {
        /*background: url(./images/checkbox-sprites1.png) left top no-repeat;*/
    }

    input[type='checkbox']:not(.native):checked + label::before,
    .checkbox input[type='checkbox']:not(.native):checked ~ label::before {
        /*background: url(./images/checkbox-sprites1.png) -19px top no-repeat;*/
        @extend .iao-fas;
        /*content: '\f046';*/
        content: '\f14a';
        font-weight: bold;
    }

    input[type='checkbox']:not(.native):checked + label.slider::before {
        content: '\f205';
        font-weight: bold;
    }

    input[type='radio'] + label::before {
        /*background: url(./images/checkbox-sprites1.png) -38px top no-repeat;*/
        /*background: url(./images/checkbox-sprites1.png) left top no-repeat;*/
    }

    input[type='radio']:checked + label::before,
    .checkbox input[type='radio']:checked ~ label::before {
        /*background: url(./images/checkbox-sprites1.png) -57px top no-repeat;*/
        /*background: url(./images/checkbox-sprites1.png) -19px top no-repeat;*/
        /*content: '\f046';*/
        content: '\f14a';
        font-weight: bold;
    }

    input[type='checkbox']:disabled + label::before,
    input[type='radio']:disabled + label::before {
        opacity: 0.4;
    }

    .dropdown-menu input[type='checkbox']:not(.native) + label,
    .dropdown-menu input[type='radio'] + label {
        margin-left: 26px;
    }

    input[type='text'].url-invalid {
        background-color: rgba(255, 255, 0, 0.25);
        border-color: rgba(255, 0, 0, 0.75);
    }

    div.url-invalid {
        color: rgba(255, 0, 0, 0.75);
    }

    .simple-central,
    .errorCentral {
        width: 90%;
        /*margin: 20px auto 20px auto;*/
        background-color: #fff;
        padding: 10px;
        border-radius: 0;
    }

    .simple-central,
    .errorCentral,
    .bannerCentral {
        /*box-shadow: 2px 2px 3px rgba(0,0,0,0.4);
	    border-left: 1px solid var(--ia-app-hi-color);
	    border-right: 1px solid var(--ia-app-hi-color);*/
        overflow: hidden;
    }

    .simple-central {
        padding: 0;
        /*min-height: 400px;*/
        /*border: 1px solid #333;*/
        display: block;
        margin-top: 3px;
        /*z-index: 30;*/
        /*margin-left: 150px;*/
        position: relative;
        /*margin-right: 150px;*/
        width: 88%;
        background-color: transparent;
    }

    .simple-central .opq {
        opacity: 1;
        background-color: #fff;
        /*min-height: 400px;*/
        position: relative;
    }

    .min400 {
        min-height: 400px;
    }

    .iaoTopper {
        position: relative;
        z-index: 1;
        /*left: 100px;
	    top: 20px;*/
        padding: 4px 15px 10px 4px;
        background-color: #f4f1ea;
        /* border-left: 1px solid var(--ia-app-hi-color); */
        /* border-right: 1px solid var(--ia-app-hi-color); */
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        max-width: 100%;
        min-height: 40px;
    }

    body.anon .authContent,
    body.anon .nav > li > a.authContent,
    body.anon .nav > li.authContent,
    body.auth .anonContent,
    body.auth.nav > li > a.anonContent,
    body.auth.nav > li.anonContent {
        display: none; /* invisible by default - script turns it back on */
    }

    body.auth .authContent,
    body.auth .nav > li > a.authContent,
    body.auth .nav > li.authContent {
        display: inherit; /* invisible by default - script turns it back on */
    }

    body.anon .anonContent,
    body.anon .nav > li > a.anonContent,
    body.anon .nav > li.anonContent {
        display: inherit; /* invisible by default - script turns it back on */
        z-index: 1;
        position: relative;
    }

    .utilityPage h1,
    h1.utility {
        font-size: 1.5em;
    }

    .iaoTopper ul {
        margin: 4px 0;
        padding: 0;
    }

    .iaoTopper ul li {
        display: inline-block;
        white-space: nowrap;
    }

    .iaoTopper ul li::before {
        content: '\f111';
        display: inline-block;
        margin-left: 2px;
        margin-right: 2px;
        color: #999;
        font-size: 0.1em;
        padding-bottom: 2px;
        vertical-align: middle;
        @extend .iao-fas;
    }

    .iaoTopper ul li:first-child::before {
        content: '';
        display: none;
    }

    .iaoTopper a {
        /*font-variant: small-caps;
	    font-weight: bold;*/
        text-decoration: none;
        color: #333;
    }

    .iaoTopper a span:first-letter {
        font-size: 1.1em;
    }

    .iaoTopper img.iaoIcon {
        width: 80px;
        height: 80px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4), 0 0 2px rgba(255, 255, 255, 0.4);
        margin-bottom: 10px;
        margin-left: 10px;
    }

    .landingBoxLinks .dataset {
        display: inline-block;
        min-width: 180px;
        width: 28%;
        margin-left: 2%;
        margin-right: 2%;
        height: 80px;
        min-height: 80px;
        background-color: #999;
        border: none;
        text-align: left;
        border-radius: $iaoBorderRadiusDef;
    }

    .landingBoxLinks .dataset div[class*='icon-'] {
        float: left;
        color: #fff;
        height: 100%;
        padding: 10px 5px 0 10px;
    }

    .landingBoxLinks .dataset div[class*='icon-']::before {
        color: #fff;
    }

    .landingBoxLinks .dataset h3 {
        margin: 2px 5px 2px 5px;
    }

    .landingBoxLinks .dataset p {
        margin: 0 5px 0 5px;
    }

    .landingBoxLinks .dataset h3,
    .landingContent h2 {
        font-family: Roboto, Sans-Serif;
        letter-spacing: 0.05em;
    }

    .landingBoxLinks .dataset [class^='fa-']::before,
    .landingContent [class*=' 32-icon-']::before {
        color: #5f9b5a;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        width: 36px;
    }

    .dataset .fas {
        min-width: 36px;
        min-height: 36px;
        display: inline-block;
    }

    .datasetActions {
        .fas,
        .far {
            min-width: 24px;
            min-height: 24px;
            width: 24px;
            height: 24px;
        }
    }

    .big-tiles .datasetActions {
        .fas,
        .far {
            min-width: 24px;
            min-height: 24px;
            width: auto;
            height: 24px;
        }
    }

    .banner [class*=' 32-icon-']::before {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    }

    .banner .newProfileBtn,
    .btn-call-action {
        cursor: pointer;
        background-color: $iaoActionColor;
        /*border: 1px solid e16b17;*/
        border-radius: $iaoBorderRadiusDef;
        /* background-image: linear-gradient(top, #e16b17 31%, $iaoActionColor);
        /* background-image: -o-linear-gradient(top, #e16b17 31%, $iaoActionColor);
        /* background-image: -moz-linear-gradient(top, #e16b17 31%, $iaoActionColor);
        /* background-image: -webkit-linear-gradient(top, #e16b17 31%, $iaoActionColor);
        /* background-image: -ms-linear-gradient(top, #e16b17 31%, $iaoActionColor);*/
        /*box-shadow: inset 2px 2px 4px rgba(255,255,255,0.4), inset -2px -2px 4px rgba(0,0,0,0.4);*/
        /*box-shadow: inset 0 0 5px rgba(225,107,23,0.7);*/
        border: none;
        color: #fff; /*darken($standardTextColor, 20%);*/
    }

    .banner .newProfileBtn {
        display: block;
        margin-top: -10px;
        font-size: 1.2em;
        padding: 10px;
        font-weight: bold;
    }

    .banner .newProfileBtn:hover,
    .banner .newProfileBtn:focus,
    .btn-call-action:hover,
    .btn-call-action:focus {
        box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.5);
    }

    .arc-connected .banner .new-report-button {
        margin-right: 145px;
    }

    .arc-connected .banner,
    .arc-connected .banner .bannerCentral {
        overflow: visible;
    }

    .arc-connected .new-report-button .btn {
        border: 2px solid rgba(255, 255, 255, 0.8);
        font-weight: 500;
        min-width: 11em;
        border-radius: 2px;
    }

    .arc-connected .new-report-button > .dropdown-menu {
        border-width: 2px;
        font-size: 1em;
        top: 91%;
        padding: 18px 5px 10px 5px;
    }

    .landingBoxLinks .dataset:hover {
        /*box-shadow: inset 2px 2px 3px #5f9b5a, inset -2px -2px 3px #5f9b5a;*/
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
    }

    .landingBoxLinks a {
        text-decoration: none;
        color: inherit;
    }

    .landingContentSeparator {
        margin-top: 15em;
    }

    .landingContent {
        padding: 10px;
    }

    .landingContent .icon-arrow-up2 {
        text-decoration: none;
    }

    .localesHolder {
        margin-left: 20px;
    }

    .errorCentral h1 {
        padding-top: 5px;
        -ms-word-break: break-all;
        word-break: break-all;
    }
    /* Welcome page(s) */
    .banner,
    .iaoFooter {
        opacity: 0.9;
        position: relative;
        /*background-color: #333;*/
        color: #fff;
        /*padding: 0 20px 0 20px;*/
        margin: 0 0 0 0;
    }

    .banner {
        margin-top: 40px;
    }
    /*.popupDialogReport .popupDialogTitleBar,*/
    /*.banner .bannerCentral,*/
    .popupDialogTitleBar,
    .themeSidebar,
    .simpleInfoWindow .title /* ArcGIS Online specifics */ {
        /* border-bottom: 1px solid #999999; */
        /* border-top: 1px solid #333; */
        /* border-top-left-radius: 1px; */
        /* border-top-right-radius: 1px; */
        /*background-color: $iaoLoColor;
        color: #fff;*/
        z-index: 10; /* force over other things */
    }

    .iaoFooter {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 1px;
        border-bottom-right-radius: 1px;
        font-size: 0.85em;
        position: relative;
        margin-top: 5.5em;
        margin-bottom: 0.5em;
        /* border: 1px solid #555 !important; */
        padding: 1px 1px 2px 1px;
        color: #666;
    }

    .simpleInfoWindow {
        padding: 0;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        height: auto !important;
    }

    .simpleInfoWindow .content,
    .simpleInfoWindow .title {
        padding: 5px;
        height: auto !important;
    }

    .banner a,
    .iaoFooter a {
        color: inherit;
        text-decoration: none;
    }

    .arcgisWebMap,
    .arcgisWebMappingApplication {
        cursor: default;
    }
    /*.arcgisWebMap::after, .arcgisWebMappingApplication::after {
        content: " ";
        font-family:  $iaoIconFontFamily;
        font-size: 18px;
        position: absolute;
        top: 0;
        right: 5px;
        background-repeat: no-repeat;
        width: auto;
        height: auto;
        color: rgba(128,128,128,0.24);
    }

    .arcgisWebMap::after {
        content: '\f0ac';
    }

    .arcgisWebMappingApplication::after {
        content: '\f1c5';
    }*/
    @media only screen and (max-width: 1400px), only screen and (min-width: 1200px) {
        .simple-central,
        .iaoTopper {
            max-width: 1260px;
        }
    }

    @media only screen and (max-width: 999px), only screen and (max-height: 800px) {
        .iaoTopper img.iaoIcon {
            width: 60px;
            height: 60px;
        }
    }

    @media only screen and (device-width: 768px) and (orientation: landscape) {
        /* rules for iPad in landscape orientation */
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        /* iPhone, Android rules here */
    }

    .lofi .arcgisWebMap::after,
    .lofi .arcgisWebMappingApplication::after {
        background-image: none;
        @extend .iao-fas;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 5px;
        font-size: 16px;
        width: 18px;
        height: 18px;
        background-color: rgba(255, 255, 255, 0.7);
        color: rgba(0, 0, 0, 0.6);
        border-radius: 7px;
    }

    .lofi .arcgisWebMappingApplication::after {
        /*content: '\e6a3';*/
        content: ' ';
        display: none;
    }

    .lofi .iaoReport::after {
        content: '\f1c5';
        display: block;
        /*content: '\e6f8';*/
    }

    .homeIcon img {
        border-left: 1px solid rgba(0, 0, 0, 1);
        border-right: 1px solid rgba(0, 0, 0, 1);
        border-top: none;
        border-bottom: none;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
        border-radius: $iaoBorderRadiusDef;
    }
    /* Index page layout stuff */
    .ia-index-page-list {
    }

    .ia-index-page-button ul li,
    .ia-index-page-carousel ul li {
        box-shadow: inset -2px -2px 3px rgba(0, 0, 0, 0.5), inset 2px 2px 3px rgba(255, 255, 255, 0.5);
        border: 1px solid #999;
        border-radius: 4px;
        background-color: #ccc;
    }

    .ia-index-page-button ul li:active,
    .ia-index-page-carousel ul li:active {
        box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.5), inset -2px -2px 3px rgba(255, 255, 255, 0.5);
    }

    .ia-index-page-button ul li a,
    .ia-index-page-carousel ul li a {
        color: #333;
        text-decoration: none;
        display: inline-table;
        width: 100%;
        height: 100%;
        padding: 0;
        white-space: normal;
    }

    .ia-index-page-button ul li a:hover span,
    .ia-index-page-carousel ul li a:hover span {
        text-decoration: underline;
        -moz-text-decoration-color: rgba(0, 0, 0, 0.5);
        text-decoration-color: rgba(0, 0, 0, 0.5);
    }

    .ia-index-page-button ul li a span,
    .ia-index-page-carousel ul li a span {
        display: table-cell;
        vertical-align: middle;
        padding: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
    }

    .push-bubble {
        position: relative;
        float: left;
        background-color: rgba(255, 255, 180, 0.6);
        border: 2px dashed rgba(255, 0, 0, 0.8);
        border-radius: $iaoBorderRadiusDef;
        overflow: hidden;
        transition: all 0.3s;
    }

    .click-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        cursor: default;
        z-index: 1000;
    }

    .star-box {
        position: relative;
        width: 80px;
        height: 16px;
        display: inline-block;
        background-color: #fff;
        display: none;
    }

    .star-box-bar {
        position: absolute;
        left: 0;
        top: 0;
        color: var(--ia-app-hi-color);
        height: 16px;
        overflow: hidden;
    }

    .star-box-stars {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 16px;
        /*background-image: url(./images/icons16/star_strip5_white_green.png);*/
    }

    .star-box-bar::before {
        content: '\f005\f005\f005\f005\f005';
        @extend .iao-fas;
        font-size: 16px;
    }

    .star-box-stars::before {
        content: '\f005\f005\f005\f005\f005';
        @extend .iao-fas;
        font-size: 16px;
        color: #333;
        font-weight: normal;
    }
    /* ArcGIS override for mapping */
    .claro .esriLargeSlider {
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
    }

    .claro .dijitRuleMark {
        border-color: #999;
    }

    .claro .dijitSlider .dijitSliderProgressBarV,
    .claro .dijitSlider .dijitSliderBottomBumper {
        /*background-color: #66a039;*/
    }

    @-webkit-keyframes imgSlideFadeInOut1a {
        0% {
            top: 0;
            opacity: 1;
        }

        17% {
            top: 0;
            opacity: 1;
        }

        25% {
            top: 18px;
            opacity: 1;
        }

        26% {
            top: 18px;
            opacity: 0;
        }

        92% {
            top: -18px;
            opacity: 0;
        }

        100% {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes imgSlideFadeInOut1a {
        0% {
            top: 0;
            opacity: 1;
        }

        17% {
            top: 0;
            opacity: 1;
        }

        25% {
            top: 18px;
            opacity: 1;
        }

        26% {
            top: 18px;
            opacity: 0;
        }

        92% {
            top: -18px;
            opacity: 0;
        }

        100% {
            top: 0;
            opacity: 1;
        }
    }

    .localeLink img {
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        /*-webkit-animation-name: imgSlideFadeInOut1;
        -webkit-animation-duration: 16s;
        -moz-animation-name: imgSlideFadeInOut1;
        -moz-animation-duration: 16s;
        -o-animation-name: imgSlideFadeInOut1;
        -o-animation-duration: 16s;
        animation-name: imgSlideFadeInOut1;
	    animation-duration: 16s;*/
        -webkit-animation-timing-function: ease-in-out;
        -moz-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .localeLink {
        position: relative;
        display: block;
        width: 32px;
        height: 16px;
        overflow: hidden;
        opacity: 0.5;
        font-size: 11px;
        text-decoration: none;
        color: #eee;
        margin-bottom: 5px;
    }

    .localeLink:hover {
        opacity: 1;
    }

    ul.reportTemplatesList {
        margin: 0;
        padding: 0;
    }

    li.template,
    .templateDesc {
        min-height: 250px;
        background-color: #fff;
        margin: 10px 15px 10px 15px;
        padding: 0 15px 0 15px;
    }

    li.template {
        position: relative;
        width: 230px;
        float: left;
        height: auto;
        border: 1px solid #fff;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.4);
        list-style: none;
        display: block;
        overflow: hidden;
    }

    li.template .templateDesc,
    .hover-details .details {
        position: absolute;
        left: -260px;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        opacity: 0;
        -moz-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    .hover-details {
        overflow: hidden;
    }

    .hover-details .standard {
        background-color: #fff;
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
    }

    .hover-details .details {
        left: 0; /* because Chrome especially has a hover fit! */
        padding: 5px 5px 25px 5px;
        background-color: #fff;
        font-size: var(--ia-app-body-font-size-small, 13px);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }

    .hover-details .details h5 {
        font-weight: bold;
        text-align: center;
    }

    .hover-details .details .btns {
        position: absolute;
        bottom: 5px;
        left: 5px;
    }

    li.template:hover .templateDesc,
    li.template:active .templateDesc,
    li.template:focus .templateDesc,
    .hover-details:hover .details,
    .hover-details:active .details,
    .hover-details:focus .details {
        left: 0;
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    .hover-details:hover .standard,
    .hover-details:active .standard,
    .hover-details:focus .standard {
        -moz-transform: scale(3);
        -ms-transform: scale(3);
        -o-transform: scale(3);
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }

    #reportTemplatesList > li {
        clear: both;
        list-style: none;
    }

    #reportTemplatesList > li:first-of-type > h4 {
        border-top: none;
    }

    #reportTemplatesList {
        padding-left: 0;
    }

    li.template > div {
        margin: 0;
        padding-top: 10px;
    }

    .templateTitle {
        font-weight: bold;
    }

    p.templateTitle {
        position: absolute;
        bottom: 0;
        /* background-color: rgba(255,255,255,0.7); */
        width: 100%;
        margin: 5px 5px 0 5px;
        padding: 5px;
        text-align: center;
    }

    li.template .templateImage {
        height: 270px;
    }

    li.template .templateImage img,
    .templateDesc .imgSlider,
    .templateDesc .templateImage img {
        width: 170px;
        height: auto;
        /*box-shadow: 0 0 1px rgba(0,0,0,0.7);*/
        margin-bottom: 2em;
    }

    li.arcItem {
        position: relative;
        width: 120px;
        float: left;
        height: 160px;
        min-height: 50px;
        list-style: none;
        display: block;
        padding: 5px 5px 5px 5px;
        margin: 10px 10px 10px 0;
    }

    li.arcItem {
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: $iaoBorderRadiusDef;
        /* box-shadow: 1px 1px 2px rgba(0,0,0,0.4); */
        text-align: center;
        cursor: pointer;
        -ms-word-break: break-all;
        word-break: break-all;
    }

    li.dataset.square {
        min-width: 100px;
        width: 140px;
        min-height: 140px;
        height: 140px;
        -ms-word-break: normal;
        word-break: normal;
    }

    li.arcItem img {
        width: 100%;
        height: auto;
    }

    .csvList li.arcItem {
        height: 120px;
    }

    .templateSelected,
    .arcItemSelected,
    li.templateSelected,
    li.arcItemSelected {
        border: 1px solid $iaoActionColor;
        background-color: #fcdacc;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: inset 0 0 1px 1px $iaoActionColor;
    }

    .templateDesc {
        /*position: absolute;
	    top: 0;
	    left: 0;
	    width: 500px;
	    display: none;
	    padding: 15px 5px;
	    border: none;
	    box-shadow: 0 0 3px 1px rgba(0,0,0,0.4);*/
    }

    .templateDesc .templateImage {
        float: left;
        display: block;
        margin: 15px;
    }

    .imgSlider {
        padding: 0;
        display: block;
        overflow: hidden;
        margin-bottom: 5px;
    }

    .imgSlideLeft,
    .imgSlideRight {
        border: 1px solid #bbb;
        display: inline-block;
        width: 16px;
        height: 16px;
        overflow: hidden;
        border-radius: 7px;
        padding: 0;
        line-height: 12px;
        font-size: 18px;
        color: $standardTextColor;
        background-color: #eee;
        cursor: pointer;
    }

    .imgSlideLeft:hover,
    .imgSlideRight:hover {
        background-color: #ccc;
    }

    .noMgn {
        margin: 0;
    }

    .forceInline * {
        display: inline-block;
    }

    #colourSettingsCustom {
        background-color: #efefef;
        padding: 5px 0;
        border-radius: 3px;
        margin-top: 0px;
    }

    #colourSettingsCustom::after {
        content: '';
        position: absolute;
        right: 80px;
        top: -10px;
        width: 20px;
        height: 10px;
        border-bottom: 10px solid #efefef;
        border-left: 10px solid #fff;
        border-right: 10px solid #fff;
    }

    #colourSettingsExample {
        border: 1px solid #bbb;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }

    #colourSettingsExample .page-example {
        padding: 10px;
        margin: 0;
    }

    .leftTip,
    .rightTip,
    .topTip,
    .bottomTip {
        position: absolute;
        top: 200px;
        min-width: 40px;
        max-width: 250px;
        width: auto;
        min-height: 40px;
        background-color: #222;
        color: #fff;
        z-index: 999;
        padding: 0 10px 0 10px;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
        display: none;
        /*font-family: Roboto, Verdana, Sans-Serif;*/
        font-size: 0.8em;
    }

    .topTip,
    .bottomTip {
        width: 250px;
    }

    .bigTip {
        width: 600px;
        font-size: 1em;
        max-width: 2500px;
    }

    .leftTip .arrTop,
    .leftTip .arrBottom {
        position: absolute;
        top: 10px;
        left: -14px;
        background-color: transparent;
        width: 10px;
        border-left: 20px solid transparent;
        height: 12px;
    }

    .leftTip .arrTop {
        border-bottom: 12px solid #222;
    }

    .leftTip .arrBottom {
        border-top: 12px solid #222;
        top: 22px;
    }

    .rightTip .arrTop,
    .rightTip .arrBottom {
        position: absolute;
        top: 10px;
        right: -14px;
        background-color: transparent;
        width: 10px;
        border-right: 20px solid transparent;
        height: 12px;
    }

    .rightTip .arrTop {
        border-bottom: 12px solid #222;
    }

    .rightTip .arrBottom {
        border-top: 12px solid #222;
        top: 22px;
    }

    .topTip .arrTop,
    .topTip .arrBottom {
        position: absolute;
        top: -12px;
        right: 125px;
        background-color: transparent;
        width: 10px;
        border-bottom: 12px solid #222;
        height: 12px;
        width: 12px;
    }

    .topTip .arrTop {
        border-right: 12px solid transparent;
    }

    .topTip .arrBottom {
        border-left: 12px solid transparent;
        right: 137px;
    }

    .bottomTip .arrTop,
    .bottomTip .arrBottom {
        position: absolute;
        top: 100%;
        right: 125px;
        background-color: transparent;
        width: 10px;
        border-top: 12px solid #222;
        height: 12px;
        width: 12px;
    }

    .bottomTip .arrTop {
        border-right: 12px solid transparent;
    }

    .bottomTip .arrBottom {
        border-left: 12px solid transparent;
        right: 137px;
    }

    .tipContent i {
        font-style: normal;
        color: #aca;
    }

    .tipContent a {
        color: #aaf;
    }

    table.dataTable {
        border-collapse: collapse;
    }

    table.dataTable th,
    table.dataTable td {
        padding: 4px 4px 4px 4px;
    }

    table.dataTable {
        border-left: 1px solid $iaoLoColor;
        border-top: 1px solid $iaoLoColor;
    }

    table.dataTable th,
    table.dataTable td {
        border-right: 1px solid $iaoLoColor;
        border-bottom: 1px solid $iaoLoColor;
    }

    table.dataTable th,
    table.dataTable tbody th {
        background-color: #777;
        color: #ffffff;
        text-decoration: none;
        font-weight: var(--ia-app-body-font-weight, 400);
    }

    .mce-menu-item {
        .iao-font-reset,
        .mce-text {
            font-family: var(--ia-app-body-font);
            font-size: var(--ia-app-body-font-size-small, 13px);
            color: #333;
        }
    }

    .iao-3-column-menu {
        min-width: 29vw !important;

        .mce-stack-layout,
        .menu-item-set {
            width: auto !important;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .mce-stack-layout-item,
            .menu-item {
                min-width: 8em;
                width: 28%;
                padding: 5px;
                margin: 1px 2%;
                border: none;
                word-break: break-word;
                white-space: normal;

                > a {
                    padding: 0;
                    display: inline;

                    > i.fas,
                    > i.fab {
                        font-size: 1.1em;
                    }

                    > i.fab {
                        color: #844;
                    }
                }

                .mce-text.iao-labelled {
                    &.iao-branded {
                        color: #844;
                    }

                    .iao-font-reset {
                        white-space: normal;
                    }

                    .iao-icon-text {
                        font-family: inherit !important;
                        font-weight: inherit !important;
                        color: inherit !important;
                        margin-right: 3px !important;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .lofi a {
        /* padding-left: 2px; */
        /* padding-top: 3px; */
        background-image: none;
        display: inline-block;
        position: relative;
        margin-left: 0;
        overflow: visible;
    }

    .lofi a:hover {
        background-image: none;
    }

    .dropdown-menu .menu-sub-item span::after {
        @extend .iao-fas;
        speak: none;
        font-style: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        right: 0;
        top: 2px;
        position: absolute;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        content: '\f0da';
    }

    .fontList li {
        list-style: none;
    }

    .font-example {
        position: relative;
        display: inline-block;
        padding: 2px 5px 2px 5px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        font-size: 1em;
        margin-left: 10px;
        margin-bottom: 10px;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .font-example::after {
        content: 'abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ 0123456789 !$%\00a3&@ \00e1\00e9\00ed\00f1\00f3\00f6\00fa\00fc';
        position: absolute;
        left: 110%;
        top: -1em;
        width: auto;
        height: auto;
        background-color: #ff9;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        display: none;
        z-index: 999;
        padding: 5px;
    }

    .font-example:hover::after {
        display: block;
    }

    output {
        background-color: #ff9;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        border-radius: $iaoBorderRadiusDef;
        padding: 1px 2px 1px 2px;
        font-size: var(--ia-app-body-font-size-small, 13px);
        display: inline-block;
        vertical-align: top;
        margin-left: 5px;
    }

    .datasetActions {
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 138px;
        background-color: #fff;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
        opacity: 0;
        padding: 2px;
        text-align: center;
    }

    .dataset:hover .datasetActions,
    .datasetActions.focused {
        opacity: 1;
    }

    .datasetActions a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
    }

    .datasetActions a,
    .datasetActions a::before,
    .lofi .datasetActions a,
    .lofi .datasetActions a::before {
        font-size: 24px;
        margin-left: 4px;
        margin-right: 4px;
    }

    .blurb li,
    .blurb > div.collapsed,
    .blurb > div.expanded {
        margin-bottom: 20px;
    }

    .blurb .contentFlip {
        font-size: 0.75rem;
        background-color: rgba(200, 200, 200, 0.25);
        border-radius: $iaoBorderRadiusDef;
        color: rgba(0, 0, 0, 0.4);
        display: inline-block;
        padding: 1px 3px 1px 3px;
        text-decoration: none;
    }

    .blurb .contentFlip:hover {
        background-color: rgba(200, 200, 200, 0.5);
        color: rgba(0, 0, 0, 0.7);
    }

    .blurb .collapsed .expandedContent {
        visibility: hidden;
        height: 1px;
    }

    .blurb .expanded .expandedContent {
        visibility: visible;
        height: auto;
    }

    .blurb .collapsed .collapsedContent {
        visibility: visible;
    }

    .blurb .expanded .collapsedContent {
        visibility: hidden;
    }

    .blurb img.screenshot {
        border: 1px solid #999;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        float: right;
        width: 200px;
        margin: 0 10px 10px 10px;
    }

    .blurb a.explanation {
        text-decoration: none;
        border-bottom: 1px dotted #bbb;
        color: inherit;
    }
    /* Touch enhancements */
    .scruffyPaperPrev::before,
    .scruffyPaperNext::before {
        content: '';
        height: 98%;
        position: absolute;
        width: 100%;
        /*z-index: -1;*/
    }

    .scruffyPaperPrev::before {
        background: $iaoPageBackdrop;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        left: -5px;
        top: 4px;
        -webkit-transform: rotate(-2.5deg) rotateY(0);
        -moz-transform: rotate(-2.5deg);
        -ms-transform: rotate(-2.5deg);
        -o-transform: rotate(-2.5deg);
        transform: rotate(-2.5deg);
    }

    .scruffyPaperNext::before {
        background: #f6f6f6;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        right: -3px;
        top: 1px;
        -webkit-transform: rotate(1.4deg) rotateY(0);
        -moz-transform: rotate(1.4deg);
        -ms-transform: rotate(1.4deg);
        -o-transform: rotate(1.4deg);
        transform: rotate(1.4deg);
    }

    .toTopLink {
        position: fixed;
        bottom: 20px;
        right: 20px;
        display: block;
        background-color: rgba(200, 200, 200, 0.4);
        border-radius: 2px;
        padding: 2px 12px;
        text-decoration: none;
        color: inherit;
        z-index: 999;
        color: #999 !important;
        font-size: 32px;
    }

    .toTopLink:hover {
        color: $standardTextColor !important;
    }

    .leftNav,
    .rightContent {
        padding: 0;
        /* height: 100%; */
        min-height: 25em;
    }

    .leftNav {
        float: left;
        width: 200px;
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .leftNav a {
        text-decoration: none;
        padding: 3px 5px;
        border-radius: $iaoBorderRadiusDef;
        display: inline-block;
        color: var(--ia-app-hi-color);
        margin: 0;
    }

    .leftNav .toolboxScrollable li {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .rightContent {
        margin-left: 210px;
    }

    .leftNav .toolboxScrollable,
    .rightContent .toolboxScrollable {
        height: 100%;
        min-height: 25em;
        /*overflow-x: scroll;*/
        overflow-y: auto;
    }

    .rightContent .toolboxScrollable {
        height: auto;
        overflow: auto;
        border-left: 1px solid var(--ia-app-hi-color);
        box-shadow: -6px 0 9px rgba(120, 120, 120, 0.1);
        padding-left: 10px;
    }

    .leftNav .toolboxScrollable {
        padding: 10px 10px 10px 10px;
    }

    .leftNav *[class*='icon-'],
    .leftNav *[class*='icon-']::before {
        margin-right: 0;
        cursor: pointer;
        color: $standardTextColor;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .leftNav li ul {
        margin-left: 20px !important;
    }

    .leftNav .navSelected {
        background-color: #333;
        color: #fff;
    }

    .subHeading {
        background-color: #bed9d7;
    }

    .tableborder {
        border: 1px solid $iaoLoColor;
    }

    .verticalAlignTop {
        vertical-align: top;
    }

    .mainheading {
        background-color: #7cb3af;
        color: #ffffff;
    }

    .tableCollapse {
        border-collapse: collapse;
    }

    .paddingEight {
        padding: 8px;
    }

    .noRightBorder {
        border-right: none;
    }

    .noLeftBorder {
        border-left: none;
    }
    /* Overrides - left to end and marked !important */
    /*a.npl 
    {
	    padding-left: 0 !important;
    }

    .dropdown-menu a.npl 
    {
	    padding-left: 2px !important;
	    padding-right: 2px !important;
    }*/
    .iaoWidgetMenuList .dropdown-menu a {
        padding-left: 28px !important;
    }

    #topGalleryLink {
        display: none !important;
    }

    .primary-action-link > a,
    .primary-action-link > .btn,
    .primary-action-link > a:hover,
    .primary-action-link > .btn:hover,
    .primary-action-link > a:focus,
    .primary-action-link > .btn:focus {
        background-color: $iaoActionColor !important;
        /* padding: 3px 10px 2px 10px; */
        color: #fff;
        border-radius: $iaoBorderRadiusDef;
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1), inset -1px -1px 2px rgba(0, 0, 0, 0.1);
    }

    .signInLink > .dropdown-menu,
    .new-report-button > .dropdown-menu {
        position: absolute;
        width: 100%;
        background-color: $iaoActionColor !important;
        font-size: 18px;
        padding: 18px 10px 10px 10px;
        top: 94%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
        z-index: 10;
        border-left: 2px solid rgba(255, 255, 255, 0.8);
        border-right: 2px solid rgba(255, 255, 255, 0.8);
        border-bottom: 2px solid rgba(255, 255, 255, 0.8);
        box-shadow: none;
    }

    .signInLink.open > .btn,
    .new-report-button.open > .btn {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    #signInLink,
    #signOutLink {
        margin-right: 5px;
    }

    .primary-action-link:hover > a,
    .primary-action-link:hover > .btn {
        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1), inset -1px -1px 2px rgba(0, 0, 0, 0.1),
            0 0 2px rgba(0, 0, 0, 0.75);
        text-decoration: none;
    }

    .primary-action-link a,
    .primary-action-link .btn {
        color: #fff;
    }

    @-webkit-keyframes wobble {
        0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        20% {
            -webkit-transform: rotate(4deg);
            -moz-transform: rotate(4deg);
            -ms-transform: rotate(4deg);
            -o-transform: rotate(4deg);
            transform: rotate(4deg);
        }

        50% {
            -webkit-transform: rotate(-4deg);
            -moz-transform: rotate(-4deg);
            -ms-transform: rotate(-4deg);
            -o-transform: rotate(-4deg);
            transform: rotate(-4deg);
        }

        100% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    @keyframes wobble {
        0% {
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        20% {
            -moz-transform: rotate(4deg);
            -ms-transform: rotate(4deg);
            -o-transform: rotate(4deg);
            -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
        }

        50% {
            -moz-transform: rotate(-4deg);
            -ms-transform: rotate(-4deg);
            -o-transform: rotate(-4deg);
            -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
        }

        100% {
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }

    .anim-wobble::before {
        display: inline-block;
        -webkit-animation: wobble 1s infinite;
        -webkit-transform-origin: 50% 10%;
        -moz-animation: wobble 1s infinite;
        -o-animation: wobble 1s infinite;
        animation: wobble 1s infinite;
        -moz-transform-origin: 50% 10%;
        -ms-transform-origin: 50% 10%;
        -o-transform-origin: 50% 10%;
        transform-origin: 50% 10%;
    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        from {
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        to {
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .anim-spin::before {
        display: inline-block;
        -webkit-animation: spin 3s infinite;
        -webkit-animation-timing-function: linear;
        -webkit-transform-origin: 50% 50%;
        -moz-animation: spin 3s infinite;
        -o-animation: spin 3s infinite;
        animation: spin 3s infinite;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -moz-animation-timing-function: linear;
        -o-animation-timing-function: linear;
        animation-timing-function: linear;
    }

    .anim-spin-slow::before {
        -webkit-animation: spin 4s infinite;
        -moz-animation: spin 4s infinite;
        -o-animation: spin 4s infinite;
        animation: spin 4s infinite;
    }

    .spin90::before {
        display: inline-block;
        -webkit-transform: rotate(90deg);
        -webkit-transform-origin: 50% 50%;
        -moz-transform: rotate(90deg);
        -moz-transform-origin: 50% 50%;
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    .wiz-steps {
        position: relative;
        min-width: 600px;
        width: auto;
        height: 400px;
        overflow: hidden;
        max-height: calc(100vh - 220px);
    }

    .wiz-step {
        position: absolute;
        top: 0;
        right: -100%;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        width: 100% !important;
        height: 100% !important;
        padding: 0 15px 0 15px;
        overflow-y: scroll;
    }

    .wiz-at-step-1 .wiz-step:nth-child(1),
    .wiz-at-step-2 .wiz-step:nth-child(2),
    .wiz-at-step-3 .wiz-step:nth-child(3),
    .wiz-at-step-4 .wiz-step:nth-child(4),
    .wiz-at-step-5 .wiz-step:nth-child(5) {
        right: 0;
    }

    .wiz-at-step-2 .wiz-step:nth-child(1),
    .wiz-at-step-3 .wiz-step:nth-child(2),
    .wiz-at-step-4 .wiz-step:nth-child(3),
    .wiz-at-step-5 .wiz-step:nth-child(4),
    .wiz-at-step-6 .wiz-step:nth-child(5) {
        right: 100%;
    }

    .failure-message .label20-partner,
    .failure-message.row *[class*='col-'],
    .failure-message.row-fluid *[class*='col-'] {
        border: 1px solid rgb(201, 21, 21);
        border-radius: $iaoBorderRadiusDef;
        padding: 10px;
        background-color: #ffc;
        /*box-shadow: 1px 1px 2px rgba(0,0,0,0.5);*/
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .indItem {
        white-space: nowrap;
    }

    .assocItem {
        padding-left: 25px;
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    #exportZipProgressBar {
        color: #fff;
        overflow: hidden;
    }

    .exporting .report-exported-only,
    .exported .report-exporting-only {
        display: none;
    }

    .explorerTreeHolder {
        border: 1px solid #bbb;
        border-radius: $iaoBorderRadiusDef;
        padding: 10px;
        overflow: scroll;
        height: 20em;
    }

    .explorerTree {
        & > div.widgetIndicators,
        & > div > ul > li,
        div.widgetIndicators > ul > li {
            margin-left: 10px;
            padding-left: 10px;
            padding-bottom: 5px;
            padding-top: 5px;
            border-left: 1px solid #bbb;
            position: relative;
            list-style: none;
        }

        & > div:last-child,
        & > div > ul > li:last-child,
        div.widgetIndicators > ul > li:last-child {
            border-left: none;
        }

        & > div::before,
        & > div > ul > li::before,
        div.widgetIndicators > ul > li::before {
            content: ' ';
            width: 10px;
            height: 1em;
            border-bottom: 1px solid #bbb;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
        }

        & > div:last-child::before,
        & > div > ul > li:last-child::before,
        div.widgetIndicators > ul > li:last-child::before {
            border-left: 1px solid #bbb;
        }

        & > div.indicator-search-panel::before {
            display: none;
        }

        & > div.indicator-search-panel {
            border-left: none;
        }

        & > div ul,
        & > div ul li:last-child,
        div.widgetIndicators ul li:last-child {
            margin-bottom: 0;
        }

        & > div > ul,
        div.widgetIndicators > ul {
            padding-left: 0;
        }

        .widgetIndicators li input[type='checkbox'] {
            margin-left: 0;
        }

        .widgetIndicators li input[type='number'] {
            vertical-align: top;
            padding: 1px 2px 1px 2px;
            height: auto;
        }

        .modelControls a {
            display: inline-block; /* Override default from data manager, because we edit these inline */
            /*margin-right: 0.4em;*/
        }
    }

    .explorerTree.checked-only {
        .widgetIndicators,
        .igroup-children > li {
            display: none;
        }

        .descendant-checked,
        .igroup-children > li.descendant-checked,
        .igroup-children > li.child-checked {
            display: block;
        }
    }

    .indicator-search-panel .input-group-btn .btn::after {
        content: attr(data-search-at) ' of ' attr(data-search-max);
        position: absolute;
        right: 110%;
        top: 50%;
        margin-top: -0.5em;
        pointer-events: none;
        color: #aaa;
        font-size: 0.8em;
        background-color: #fff;
    }

    .modal-content .explorerTree.fixed {
        max-height: calc(100vh - 240px);
    }

    .popover.bottom > .arrow.arrow-colored::after {
        border-bottom-color: inherit !important;
    }

    .popover-wide {
        max-width: 500px;
    }

    .popover-content > .metadata-container,
    .modal-body .metadata-container {
        overflow: auto;
        max-height: 20em;
    }

    .popover-content .metadata-container {
        margin: -9px -14px;
        padding: 5px;
    }

    .popover-content .metadata-container table a {
        -ms-word-break: break-all;
        word-break: break-all;
    }

    .modal-body .metadata-container {
        max-height: calc(100vh - 60px - 14em);
        margin-right: -10px;
        margin-bottom: -10px;
    }

    .popover-content > .metadata-container {
        min-height: 120px;
        height: auto;
        max-height: calc(100vh - 400px);
    }

    .popover.right,
    .popover.left {
        .popover-content > .metadata-container {
            min-height: calc(100vh - 400px);
            height: auto;
            max-height: calc(100vh - 400px);
        }
    }

    .popover-content .metadata-table {
        font-size: var(--ia-app-body-font-size-small, 13px);
    }

    .popover .metadata-table,
    .modal .metadata-table {
        width: 100%;

        thead {
            display: none;
        }

        td {
            padding: 3px 5px;
            vertical-align: top;
        }

        tr:nth-child(odd) {
            background-color: #eee;
        }

        td:first-child {
            color: var(--ia-app-hi-color);
        }
    }

    .warning-bar,
    .message-bar {
        position: fixed;
        left: 0;
        bottom: -26px;
        z-index: 65536;
        width: 100%;
        height: 25px;
        padding: 4px 20px;
        font-size: var(--ia-app-body-font-size-small, 13px);
        background-color: rgba(0, 0, 0, 0.7);
        -moz-transition: all 0.6s;
        -o-transition: all 0.6s;
        -webkit-transition: all 0.6s;
        transition: all 0.6s;
    }

    .message-bar {
        color: #fff;

        .progress {
            height: 15px;
            max-height: 15px;
            margin-top: 1px;
            border-radius: 2px;
        }
    }

    .warning-bar.shown,
    .message-bar.shown,
    .warning-bar.in,
    .message-bar.in {
        bottom: 0;
    }

    .modal-body .tabContent .explorerTree {
        border: none;
        height: auto;
        overflow: hidden;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    .igroup-name > a.nodef {
        display: inline-block;
        vertical-align: top;
    }

    .popupEditor {
        border: 1px solid rgb(204, 204, 204);
        background-color: rgb(238, 238, 238);
        border-radius: $iaoBorderRadiusDef;
        box-shadow: rgba(0, 0, 0, 0.498039) 1px 1px 1px;
    }

    .explorerTree .popupEditor {
        width: 101%;
        min-height: 104%;
        border: none;
        background-color: #fefefe;
        box-shadow: none;
        margin: -2px 0;
        padding: 0 3px;
        border-radius: 0;
    }

    .popupEditor a {
        display: inline-block;
        margin-top: 2px;
    }
    /* Paler Skin - start */
    .paleGrey,
    .paleGray {
        color: #aaa;
    }

    .pale #designPageToolbar,
    .pale .topNavBar {
        /* background-color: #dfdfe0; */
        /* background-image: none; */
    }

    .pale .design-control-popper a {
        color: inherit;
    }

    .pale .toolboxTitleBar {
        border-bottom: 1px groove #999;
    }

    .pale .menu-items {
        border-bottom-color: #111;
    }

    .pale #availableWidgetsBox .widget {
        /*color: #111;*/
    }
    /* Paler Skin - end */
    .tooltip {
        z-index: $zIndexToolTip;
        font-size: var(--ia-app-body-font-size-small, 13px);
        line-height: initial;
        padding: 5px 0 6px 0;
        text-align: left;
        pointer-events: none;
    }

    .iaChartWidget map area {
        cursor: crosshair; /* not pointer because that messes with tooltips if they are below */
    }

    .ia-splash-screen {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.85);
        z-index: $zIndexPopup;
        cursor: wait;
        overflow: hidden;
        font-size: var(--ia-app-body-font-size, 15px);
    }

    .ia-splash-screen-full {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .ia-splash-central {
        display: table;
        width: 100%;
        height: 100%;
    }

    .ia-splash-central > div {
        display: table-cell;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
    }

    .hover-trigger {
        position: relative;
    }

    .iaoTopper .hover-trigger {
        padding: 0 5px;
        background-color: #f4f1ea;
    }

    .hover-trigger .hover-only {
        display: none;
        opacity: 0;
        background-color: inherit;
        width: 100%;
        padding: 5px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        -moz-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    .reportEditBar .hover-only {
        background-color: #dfdfe0;
    }

    .hover-trigger:hover .hover-only {
        display: block;
        opacity: 0.95;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    .hover-only .itm {
        margin-top: 5px;
    }
    /* Overrides when icons are used elsewhere in text UI */
    .fa,
    .fas,
    .far {
        font-family: 'Font Awesome 5 Free', FontAwesome !important;
    }

    .fas {
        font-weight: bold;
    }

    .fa-power-off:hover,
    a:hover .fa-power-off {
        color: #bb2222;
    }

    .label40 {
        display: inline-block;
        width: 40%;
        text-align: right;
    }

    .label40-partner {
        width: 59%;
        display: inline-block;
        padding-left: 10px;
        text-align: left;
    }
    /* jQuery UI helpers */
    .ui-draggable-handle {
        -ms-touch-action: none;
        touch-action: none;
        cursor: move;
    }

    .ui-resizable {
        position: relative;
    }

    .ui-resizable-handle {
        position: absolute;
        font-size: 0.1px;
        display: block;
        -ms-touch-action: none;
        touch-action: none;
    }

    .ui-resizable-disabled .ui-resizable-handle,
    .ui-resizable-autohide .ui-resizable-handle {
        display: none;
    }

    .ui-resizable-se {
        cursor: se-resize;
        width: 12px;
        height: 12px;
        right: 0;
        bottom: 0;
    }

    .ui-resizable-e {
        cursor: e-resize;
        width: 1px;
        height: 100%;
        right: 0;
        bottom: 0;
    }

    .ui-resizable-s {
        cursor: s-resize;
        width: 100%;
        height: 1px;
        right: 0;
        bottom: 0;
    }

    .modal-footer-abs {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    li .i-name {
        -ms-word-break: break-all;
        word-break: break-all;
    }

    li .i-date::before {
        color: #666;
        content: '\f073';
        @extend .iao-fas;
        font-size: 1em;
        font-weight: normal; /* Reset - this icon is better normal rather than bold*/
        margin: 0 5px;
    }

    .i-date:empty {
        display: none;
    }

    .navbar .userName {
        display: inline-block;
        overflow-x: hidden;
        width: 0;
        height: 1em;
    }

    .disabled-but-hoverable {
        pointer-events: auto !important;
    }

    #designPageToolbar .navbar-header {
        margin-left: 160px;
        /* background-color: yellow; */
        width: auto;
        /* margin-right: 5px; */
    }

    #designPageToolbar {
        /* padding-right: 50px
     */
    }

    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }

    .row.no-gutters > [class^='col-'],
    .row.no-gutters > [class*=' col-'] {
        padding-right: 0;
        padding-left: 0;
    }

    .row.lo-gutters {
        margin-right: 5px;
        margin-left: 5px;
    }

    .row.lo-gutters > [class^='col-'],
    .row.lo-gutters > [class*=' col-'] {
        padding-right: 5px;
        padding-left: 5px;
    }

    .row.voff-2 {
        margin-bottom: 2px;
    }

    .row.voff-5 {
        margin-bottom: 5px;
    }

    .errMsg,
    .error-message {
        padding: 5px;
        border: 1px dotted $iaoPageBackgroundGrey;
        border-radius: 3px;
        background-color: #f8f8f8;
        color: red;
        font-size: var(--ia-app-body-font-size-small, 13px);
        word-break: break-all;
        display: inline-block;
    }

    ul.errMsg {
        padding-left: 25px;

        li {
            margin-left: 20px;
            -ms-word-break: break-all;
            word-break: break-all;
        }

        li:empty {
            display: none;
        }
    }

    #messagesDropDown {
        width: 33vw;

        li {
            -ms-word-break: break-all;
            word-break: break-all;

            a {
                white-space: normal;
            }
        }
    }
    /* Color picker */
    #ia-color-picker {
        position: absolute;
        display: none;
        border-radius: 5px;
        background-color: #f8f8f8;
        border: 1px solid $iaoPageBackgroundGrey;
        z-index: $zIndexPopup + 1;
        padding: 8px;
        width: 210px;
    }
    /* Specific items */
    #geosDropDownListHolder {
        min-width: 300px;
        height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    /* Azure related */
    .cloud-drop-target {
        float: right;
        margin-top: 10px;
        min-width: 100px;
        width: 140px;
        min-height: 140px;
        height: 140px;
        padding: 10px 20px;
        text-align: center;
        font-size: 56px;
        /*color: rgba(0,0,0,.2);*/
        border: 1px solid rgba(150, 150, 150, 0.3);
        background-color: rgba(228, 228, 228, 0.3);
        margin-left: 5px;
        border-radius: $iaoBorderRadiusDef;
        overflow: hidden;
    }

    .rows {
        .toolboxScrollable {
            padding-left: 15px;
        }

        .cloud-drop-target {
            min-width: 100px;
            width: auto;
            min-height: 10px;
            height: auto;
            padding: 5px 30px 5px 5px;
            font-size: 16px;
            text-align: left;
            margin-bottom: 5px;
        }

        .cloud-drop-target > div.btn {
            display: inline-block !important;
        }

        .dataset.active {
            background-color: $iaoBgGrey !important;
            border-radius: 1px 1px 0 0;
            border-bottom: none;
            border-left: 1px solid $iaoBorderGrey;
            border-right: 1px solid $iaoBorderGrey;
            border-top: 1px solid $iaoBorderGrey;

            + .theme-children.in {
                border-top: none;
            }
        }

        .dataset.item-collection-search-results .dsName > span::after {
            content: '(' attr(data-query-text) ', ' attr(data-query-result-count) ' matches)';
            font-size: 0.9em;
            margin-left: 5px;
            font-style: italic;
        }

        .theme-children {
            position: relative !important;
        }

        .theme-children.in {
            overflow: visible;
            height: auto !important;
            min-height: 0 !important;

            .filter-box-group,
            .close-dialog-btn {
                top: -39px;
            }

            .toolboxScrollable {
                height: auto !important;
                min-height: 100px;
                overflow: hidden;
            }

            .themeSidebar {
                width: 1.9em !important;

                .iao-icon,
                p > span {
                    display: none;
                }
            }
        }

        .dataset .thumbnail-image {
            height: 32px;
            width: auto;
        }

        .star-box {
            position: absolute;
            width: 80px;
            height: 16px;
            left: 85px;
            bottom: 10px;
            margin: 0;
            display: block;
        }

        .arcgisWebMaps .iaoReport,
        .arcgisWebMaps .arcgisWebMappingApplication,
        .iaoReports .arcgisWebMap,
        .arcgisWebMaps .arcgisFeatureService,
        .iaoReports .arcgisFeatureService,
        .arcgisWebMaps .arcgisCSV,
        .iaoReports .arcgisCSV,
        .arcgisData .iaoReport,
        .arcgisData .arcgisWebMappingApplication,
        .arcgisData .arcgisWebMap {
            display: none;
        }
    }

    .drop-target-active {
        background-color: rgba(255, 255, 128, 0.5);
        color: rgba(0, 0, 0, 0.8);
        border: 1px dotted rgba(255, 128, 128, 0.7);
    }

    #azureWebApps .azure-private::after {
        position: absolute;
        content: '\f023';
        top: 2px;
        right: 5px;
        font-size: 14px;
        @extend .iao-fas;
        color: rgba(120, 120, 120, 0.5);
    }

    .arc-connected .big-tiles .dataset .thumbnail-image {
        display: none;
    }

    #azureWebApps.big-tiles .azure-private::after {
        background-color: #fff;
        padding: 0 0 0 3px;
        border-radius: $iaoBorderRadiusDef;
    }

    .azure-content .tabHolder,
    .modal .tabHolder {
        height: 33px;
    }

    .azure-content .tabContent {
        position: relative;
        background-color: #fff;
        /*border-top-color: rgba(155, 155, 155, 0.7);*/
        padding-top: 10px;
        border-bottom: none;
    }

    .btn-file input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        background: none repeat scroll 0 0 transparent;
        cursor: inherit;
        display: block;
    }

    .popover.bottom > .arrow:after {
        border-bottom-color: var(--ia-app-hi-color);
    }

    .popover.top > .arrow:after {
        border-top-color: var(--ia-app-hi-color);
    }

    .popover.left > .arrow:after {
        border-left-color: var(--ia-app-hi-color);
    }

    .popover.right > .arrow:after {
        border-right-color: var(--ia-app-hi-color);
    }

    .popover-title,
    .popover {
        background-color: var(--ia-app-hi-color);
    }

    .popover-title {
        color: var(--ia-app-hi-color-negative, #fff);
        font-size: 1.1em;
    }

    .popover-content {
        background-color: #fff;
    }

    .popover {
        border-radius: $iaoBorderRadiusDef;
    }

    .modal .preview-report {
        width: 640px;
        height: 480px;
        margin: 0 auto;
        overflow: hidden;
    }

    .modal .preview-report .ia-report {
        min-width: 200px;
        min-height: 200px;
    }

    .modal .preview-data {
        min-width: 800px;
        min-height: 480px;
        margin: 0;
    }

    .ia-datatip,
    .mapTooltip,
    .map .esriMapTooltip {
        background-color: #111;
        color: #fff;
        z-index: $zIndexToolTip;
        font-size: 1em;
        border: none;
        border-radius: 2px 2px 2px 0;
        box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.3);
        overflow: visible !important;
        opacity: 1;
        /*margin-top: -3em;*/
        width: auto;
        max-width: 200px;
    }

    .map .iao-selection-toolbar {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0.9em;
        opacity: 0.75;
        transition: all 0.3s;
    }

    .map .iao-selection-toolbar:hover {
        opacity: 1;
    }

    .map-full-screen .map .iao-selection-toolbar {
        top: 70px;
    }

    .map .esriMapTooltip,
    .smaller-block {
        font-size: 0.9em;
    }

    .ia-datatip::before,
    .mapTooltip::before {
        position: absolute;
        content: ' ';
        left: 0;
        top: 100%;
        border-right: none;
        border-top: none;
        border-bottom: 8px solid rgba(0, 0, 0, 0.9);
        border-left: 8px solid rgba(0, 0, 0, 0);
        width: 8px;
        height: 8px;
        margin-left: -8px;
        margin-top: -8px;
        overflow: hidden;
    }

    .ia-esri-logo img {
        width: 1px;
        height: 1px;
    }

    .ia-esri-logo::before {
        content: '';
        width: 65px;
        height: 36px;
        display: block;
        position: absolute;
        right: 100%;
        top: -20px;
        background-image: url(../images/esri-favicon-white-border.png);
    }

    .esriSimpleSlider {
        border: none;
        background-color: transparent;
    }

    .esriSimpleSliderIncrementButton,
    .esriSimpleSliderDecrementButton {
        background-color: rgba(40, 40, 40, 0.9);
        color: #fff;
        border: none;
        margin-bottom: 10px;
        border-radius: $iaoBorderRadiusDef;
        width: 36px;
        height: 36px;
        position: relative;
        overflow: hidden;
    }

    .esriSimpleSliderIncrementButton:hover,
    .esriSimpleSliderDecrementButton:hover {
        background-color: rgba(40, 40, 40, 0.95);
        box-shadow: 0 0 1px 1px #4a3875;
    }

    .esriSimpleSliderIncrementButton > span,
    .esriSimpleSliderDecrementButton > span {
        display: none;
    }

    .esriSimpleSliderIncrementButton::after {
        content: '\f067';
    }

    .esriSimpleSliderDecrementButton::after {
        content: '\f068';
    }

    .esriSimpleSliderIncrementButton::after,
    .esriSimpleSliderDecrementButton::after {
        position: absolute;
        @extend .iao-fas;
        left: 0;
        top: 0;
        width: 36px;
        height: 36px;
        font-size: 24px;
        padding: 6px 0;
    }

    .iao-rollover-table {
        position: absolute;
        left: 0;
        top: 0;
        overflow: scroll;
        -moz-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
        z-index: -1;
        background-color: #fff;
        opacity: 0;
    }

    .iao-rollover-table thead th {
        text-align: center;
    }

    .iao-rollover-table tbody td {
        text-align: right;
    }

    .iao-rollover-trigger,
    .iao-download-hover {
        position: absolute;
        right: 5px;
        bottom: 5px;
        cursor: pointer;
        opacity: 0.5;
        z-index: 1002;
        transition: all 0.3s;

        a {
            color: inherit;
        }
    }

    .iao-download-hover {
        bottom: 0;
    }

    .iaProfileWidget table ~ .iao-download-hover {
        bottom: -10px;
    }

    .iao-rollover-trigger:hover,
    .iao-download-hover:hover {
        opacity: 1;
    }

    .iao-rollover-table.rolled {
        z-index: 1001;
        display: block;
        opacity: 1;
    }

    .edit-sunken .esriSignInDialog {
        display: none;
    }

    .map-full-screen {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: none;
        z-index: $zIndexForeground;
        background-color: $iaoPageBackgroundGrey;
    }

    .map-full-screen-banner {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        padding: 10px;
        margin: 0;
        z-index: $zIndexForeground + 1;
        background-color: rgba(40, 40, 40, 0.9);
    }

    h1.map-full-screen-banner {
        color: #fff !important;
    }

    .map-full-screen-footer {
        position: fixed;
        left: 5px;
        bottom: 5px;
        width: auto;
        height: auto;
        margin: 0;
        z-index: $zIndexForeground + 1;
    }

    .map-full-screen-popup {
        position: absolute;
        z-index: $zIndexPopup;
        background-color: #fff;
        border-radius: $iaoBorderRadiusDef;
        border: 1px solid rgba(40, 40, 40, 0.9);
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        top: 20px;
        left: 20px;
        padding: 2.3em 0 0 0;
        -moz-transition: left 0.25s linear, top 0.25s linear;
        -o-transition: left 0.25s linear, top 0.25s linear;
        -webkit-transition: left 0.25s linear, top 0.25s linear;
        transition: left 0.25s linear, top 0.25s linear;
        overflow: hidden;
    }

    @media print {
        .map-full-screen-popup.fill-for-print {
            position: absolute;
            top: -1px !important;
            left: -1px !important;
            width: 100% !important;
            width: calc(100% + 2px) !important;
            height: auto !important;
            border: none;
            padding: 1px;

            .map-full-screen-popup-banner {
                display: none;
            }
        }

        .map-full-screen-popup.minified {
            display: none;
        }
    }

    .map-full-screen-popup.ui-draggable-dragging {
        -moz-transition: none;
        -o-transition: none;
        -webkit-transition: none;
        transition: none;
    }

    .map-full-screen-popup .popup-filler {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 10px 10px 10px 10px;
    }

    .map-full-screen-popup .iao-maxifier,
    .map-full-screen-popup.minified .iao-minifier,
    .map-full-screen-popup.minified .map-full-screen-popup-banner .report-title,
    .map-full-screen-popup.minified .map-full-screen-popup-banner .title-splitter,
    .map-full-screen-popup.minified .profileReport {
        display: none;
    }

    .map-full-screen-popup.minified {
        position: fixed !important;
        left: 0 !important;
        top: 100% !important;
        margin-top: -2.1em !important;
        width: 120px !important;
        height: 2.1em !important;
        overflow: hidden !important;
        padding: 0 !important;
    }

    .map-full-screen-popup.minified .ui-draggable-handle {
        cursor: default;
    }

    .map-full-screen-popup.minified.popup-2 {
        left: 121px !important;
    }

    .map-full-screen-popup.minified.popup-3 {
        left: 242px !important;
    }

    .map-full-screen-popup.minified.popup-4 {
        left: 363px !important;
    }

    .map-full-screen-popup.minified.popup-5 {
        left: 484px !important;
    }

    .map-full-screen-popup.minified.popup-6 {
        left: 605px !important;
    }

    .map-full-screen-popup.minified.popup-7 {
        left: 726px !important;
    }

    .map-full-screen-popup.minified.popup-8 {
        left: 847px !important;
    }

    .map-full-screen-popup.minified.popup-9 {
        left: 968px !important;
    }

    .map-full-screen-popup.minified.popup-10 {
        left: 1089px !important;
    }

    .map-full-screen-popup.minified .iao-maxifier {
        display: inline-block;
    }

    .map-full-screen-popup-banner {
        padding: 5px 2px 5px 10px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: rgba(40, 40, 40, 0.9);
    }

    .map-full-screen-popup.minified .map-full-screen-popup-banner {
        white-space: nowrap;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 100; /* within the mini-dialog make sure it is top-most */
    }

    .map-full-screen-popup.minified .geo-title {
        display: inline-block;
        max-width: 45%;
        overflow: hidden;
    }

    .map-full-screen-popup.minified .geo-title::after {
        content: '...';
        position: absolute;
        left: 100%;
    }

    .map-full-screen-popup-banner,
    .map-full-screen-popup-banner a {
        color: #fff;
    }

    .map-full-screen-description {
        position: fixed;
        z-index: $zIndexPopup - 1;
        background-color: #fff;
        border-radius: 0 0 2px 2px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        top: 70px;
        left: 20px;
        width: 200px;
        height: 200px;
        overflow-y: scroll;
        padding: 10px;
        line-height: 1.5;
    }

    .hidden-at-startup {
        visibility: hidden; /* Allow real-estate, but not showing */
    }

    input.file-tiny {
        width: 1px;
        height: 1px;
        overflow: hidden;
        border: none;
    }

    .page-navigation {
        position: fixed;
        right: 20px;
        bottom: 0;
        z-index: 1200;
        opacity: 0.5;
        -moz-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
    }

    .page-navigation li.page {
        display: none;
    }

    .page-navigation:hover {
        opacity: 0.9;
        max-width: 50vw;
        text-align: right;

        li {
            display: inline-block;
        }
    }

    .arc-relationships ul li,
    #geoCompFeatureBoxHolder li {
        list-style: none;
    }

    .bouncy-over-row {
        display: flex;
        height: 2em;
        flex-direction: row;
        align-items: center;

        > i,
        > span {
            transition: all 0.25s;
            display: inline-block;

            &:hover {
                font-size: 2.5em;

                + i,
                + span {
                    font-size: 1.75em;

                    + i,
                    + span {
                        font-size: 1.25em;
                    }
                }
            }
        }
    }

    @media only screen {
        .suppress-map-tiles {
            .layerTile {
                /*display: none;*/
            }
        }
    }
    /* Screen size overrides */
    @media only screen and (max-width: 600px) {
        .iao-wave .iao-profiles-name {
            width: 200px;
            height: 60px;
        }

        .iao-wave .iao-profiles-name::after {
            width: 270px;
            height: 50px;
        }

        .iao-wave::after {
            height: 50px;
        }

        img.auto-width {
            width: 200px;
        }

        .auto-width-inv-r {
            margin-right: 205px;
        }

        .auto-width-inv-l {
            margin-left: 205px;
        }

        .bannerCentral img.auto-width {
            width: 400px;
        }

        .bannerCentral .auto-width-inv-r {
            margin-right: 405px;
        }

        .bannerCentral .auto-width-inv-l {
            margin-left: 405px;
        }
    }

    @media only screen and (max-width: 750px) {
        #userFilterSet > .abs {
            position: relative;
            text-align: right;
            padding: 5px 0;
            margin: 0;
        }

        .simple-central {
            width: 92%;
        }

        .iao-wave .app-name .builder-name {
            font-size: 1.5em;
            line-height: 1em;
            white-space: nowrap;
        }

        .bannerCentral > .authContent > .authContent.abs {
            bottom: 70px !important;
            right: -10px !important;
            top: auto !important;
        }
    }

    @media only screen and (min-width: 601px) and (max-width: 999px) {
        .iao-wave .iao-profiles-name {
            width: 240px;
            height: 90px;
        }

        .iao-wave .iao-profiles-name::after {
            width: 320px;
            height: 70px;
        }

        .iao-wave::after {
            height: 50px;
        }

        img.auto-width {
            width: 300px;
        }

        .auto-width-inv-r {
            margin-right: 305px;
        }

        .auto-width-inv-l {
            margin-left: 305px;
        }

        .bannerCentral img.auto-width {
            width: 450px;
        }

        .bannerCentral .auto-width-inv-r {
            margin-right: 455px;
        }

        .bannerCentral .auto-width-inv-l {
            margin-left: 455px;
        }
    }

    @media only screen and (max-width: 1250px) {
        .navbar .userName,
        .collapse .nav .link-text {
            display: none;
        }

        .design-control-popout-holder {
            width: 150px;
        }

        .toolboxPanel .menu-items {
            padding-left: 5px;
        }

        .design-column-main {
            margin-left: 200px;
        }

        #designPageToolbar > .container-fluid > .collapse > ul.nav {
            margin-left: 180px;
        }

        .design-control-popper.active,
        .design-control-popper:hover,
        .design-control-popper:active {
            height: 60px;
            width: 60px;
            font-size: 34px;
        }

        div.design-control-popper:nth-child(6) {
            right: calc(100% - 100px);
            top: 7px;
        }

        div.design-control-popper.active:nth-child(6),
        div.design-control-popper:hover:nth-child(6) {
            right: calc(100% - 105px);
        }

        div.design-control-popper:nth-child(7) {
            right: calc(100% - 170px);
            top: 7px;
        }

        div.design-control-popper.active:nth-child(7),
        div.design-control-popper:hover:nth-child(7) {
            right: calc(100% - 170px);
        }
    }

    @media only screen and (max-width: 990px) {
        .navbar .userName,
        .collapse .nav .link-text {
            display: none;
        }
    }

    @media only screen and (min-width: 999px) {
        .bannerBackdrop {
            display: block;
        }

        .iao-wave .iao-profiles-name::after {
            /*width: 610px;
		    height: 160px;
		    top: -30px;*/
        }

        .iaoSubWave .iao-profiles-name::after {
            width: 610px;
            height: 90px;
            top: -30px;
        }

        img.auto-width {
            width: 400px;
        }

        .bannerCentral img.auto-width {
            width: 530px;
        }

        .auto-width-inv-r {
            margin-right: 405px;
        }

        .auto-width-inv-l {
            margin-left: 405px;
        }
    }

    @media only screen and (device-width: 768px) and (orientation: landscape) {
        /* rules for iPad in landscape orientation */
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        /* iPhone, Android rules here */
    }

    @media only screen and (max-height: 500px) {
        .modal-content .toolboxScrollable {
            height: 240px;
        }
    }

    @media only screen and (max-height: 400px) {
        .modal-content .toolboxScrollable {
            height: 160px;
        }
    }

    @media print {
        .warning-bar,
        .message-bar,
        .warning-bar.shown,
        .message-bar.shown,
        .warning-bar.in,
        .message-bar.in {
            display: none;
        }
    }
}

.ia-app.f-muli {
    body, form, button,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .tooltip, .dropdown-menu > li > a, /* bootstrap overrides */
    .navbar-nav > li > a,
    .navbar-default .navbar-nav > li > a,
    .pagination li a,
    #indexMapsBox, .mce-menu-item .mce-text,
    .design-column-main .iaProfileWidget:hover::before,
    select, input, textarea,
    .popover,
    h1, h2, h3, h4, h5, h6 {
        font-family: Muli, Roboto, Verdana, Arial, Helvetica, sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }
}

.ia-app.f-nunito {
    body, form, button,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .tooltip, .dropdown-menu > li > a, /* bootstrap overrides */
    .navbar-nav > li > a,
    .navbar-default .navbar-nav > li > a,
    .pagination li a,
    #indexMapsBox, .mce-menu-item .mce-text,
    .design-column-main .iaProfileWidget:hover::before,
    select, input, textarea,
    .popover,
    h1, h2, h3, h4, h5, h6 {
        font-family: Nunito, Roboto, Verdana, Arial, Helvetica, sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }
}

.ia-app.f-sourcesanspro {
    body, form, button,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .tooltip, .dropdown-menu > li > a, /* bootstrap overrides */
    .navbar-nav > li > a,
    .navbar-default .navbar-nav > li > a,
    .pagination li a,
    #indexMapsBox, .mce-menu-item .mce-text,
    .design-column-main .iaProfileWidget:hover::before,
    select, input, textarea,
    .popover,
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Source Sans Pro', Roboto, Verdana, Arial, Helvetica, sans-serif;
    }

    body {
        font-size: 16px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }
}

.ia-app.f-rubik {
    body, form, button,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .tooltip, .dropdown-menu > li > a, /* bootstrap overrides */
    .navbar-nav > li > a,
    .navbar-default .navbar-nav > li > a,
    .pagination li a,
    #indexMapsBox, .mce-menu-item .mce-text,
    .design-column-main .iaProfileWidget:hover::before,
    select, input, textarea,
    .popover,
    h1, h2, h3, h4, h5, h6 {
        font-family: Rubik, Roboto, Verdana, Arial, Helvetica, sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }
}

$darkSkinTextColor: #ececec;
$darkSkinBaseColor: #252526;

@keyframes lr-clip {
    0% {
        -webkit-clip-path: inset(0 100% 0 0);
        clip-path: inset(0 100% 0 0);
    }

    25% {
        -webkit-clip-path: inset(0 66% 0 0);
        clip-path: inset(0 66% 0 0);
    }

    50% {
        -webkit-clip-path: inset(0 33% 0 0);
        clip-path: inset(0 33% 0 0);
    }

    75%,
    100% {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
    }
}

.ia-app.skin-dark {
    color: $darkSkinTextColor;
    background-color: $darkSkinBaseColor;

    a {
        color: #639eff;
    }

    a.nodef,
    a.btn,
    .menu-items a,
    .banner a,
    .iaoFooter a,
    .datasetActions a {
        color: inherit;
    }

    body, form, button,
    .simpleInfoWindow, /* ArcGIS Online popup window specific */
    .tooltip, .dropdown-menu > li > a, /* bootstrap overrides */
    .navbar-nav > li > a,
    .navbar-default .navbar-nav > li > a,
    .dataset .fa,
    .pagination li a {
        color: $darkSkinTextColor;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--ia-app-hi-color);
    }

    .mce-container *,
    .popover,
    .bg-danger,
    .failure-message {
        color: $darkSkinBaseColor;
    }

    .pagination li a,
    .nav,
    .navbar,
    .dropdown-menu {
        background-color: $darkSkinBaseColor;
    }

    .navbar-default {
        border-color: darken($darkSkinBaseColor, 10%);

        .navbar-collapse,
        .navbar-form {
            border-color: darken($darkSkinBaseColor, 10%);
        }
    }

    .bg-grey {
        color: $darkSkinBaseColor;

        * {
            color: inherit;
        }
    }

    .pagination li a:hover,
    .pagination li.active a {
        background-color: var(--ia-app-hi-color);
        border-color: inherit;
    }

    .opq,
    .toolboxScrollable,
    #ia-color-picker,
    .ia-splash-screen-full {
        background-color: $darkSkinBaseColor;
        border-color: $darkSkinTextColor;
    }

    .bannerCentral,
    .map-full-screen-banner {
        background-color: var(--ia-app-hi-color);
        border-bottom: 10px solid #fff;
    }

    .iaoMask {
        color: $darkSkinBaseColor;
    }

    .page-tile {
        .btn-holder {
            color: $darkSkinTextColor;
        }
    }

    .iasMask .fa-circle-o-notch.fa-spin::before,
    .iaoMask .fa-circle-o-notch.fa-spin::before,
    .iao-mask .fa-circle-o-notch.fa-spin::before,
    .iao-masker .fa-circle-o-notch.fa-spin::before {
        content: ' ';
        /*background-image: url(../Images/rb4arcgis-logo192.png);*/
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        height: 1em;
        min-width: 1em;
        box-sizing: border-box;
    }

    .iasMask .fa-circle-o-notch.fa-spin,
    .iaoMask .fa-circle-o-notch.fa-spin,
    .iao-mask .fa-circle-o-notch.fa-spin,
    .iao-masker .fa-circle-o-notch.fa-spin {
        /*-moz-animation: lr-clip 1.6s steps(1) infinite;
        -o-animation: lr-clip 1.6s steps(1) infinite;
        -webkit-animation: lr-clip 1.6s steps(1) infinite;
        animation: lr-clip 1.6s steps(1) infinite;*/
        -webkit-animation: grow-shrink-fade 1.7s infinite;
        -moz-animation: grow-shrink-fade 1.7s infinite;
        -o-animation: grow-shrink-fade 1.7s infinite;
        animation: grow-shrink-fade 1.7s infinite;
    }

    .dataset,
    .dataset .datasetActions,
    .dataset .star-box,
    .big-tiles .dataset > .dsName,
    .hover-details .details,
    .hover-details .standard,
    #widgetQuickSettings .dataset,
    #widgetQuickSettings .dataset .dsName,
    .template,
    .templateDesc {
        background-color: lighten($darkSkinBaseColor, 10%);
        color: $darkSkinTextColor;
    }

    .hover-details .standard {
        padding-top: 2.5em;
        height: 100%;
        overflow: hidden;
    }

    .rows .dataset:nth-child(odd),
    .rows .dataset:nth-child(odd) .datasetActions,
    .popupDialogMessageBody {
        background-color: transparent;
    }

    .dataset .star-box-stars::before {
        color: $darkSkinTextColor;
    }

    .modal {
        .modal-content {
            .modal-body,
            .modal-header,
            .modal-footer {
                background-color: $darkSkinBaseColor;
            }

            .modal-header {
                border-bottom-color: lighten($darkSkinBaseColor, 10%);

                .modal-ui-controls {
                    background-color: $darkSkinBaseColor;
                    border-color: $darkSkinBaseColor;
                }
            }
        }
    }

    .dataset,
    .theme-children,
    .modal-content,
    #ia-color-picker,
    .template {
        border-color: lighten($darkSkinBaseColor, 10%);
    }

    .dataset.active {
        border-top: 1px solid lighten($darkSkinBaseColor, 10%);
        border-left: 1px solid lighten($darkSkinBaseColor, 10%);
        border-right: 1px solid lighten($darkSkinBaseColor, 10%);
        border-bottom: none;
    }

    .themeSidebar {
        background-color: lighten($darkSkinBaseColor, 10%);
        color: $darkSkinTextColor !important;
        font-weight: var(--ia-app-body-font-weight, 400);
        border-right: 1px solid $darkSkinBaseColor;
    }

    .theme-children {
        background-color: $darkSkinBaseColor;

        .close-dialog-btn {
            color: $darkSkinTextColor;
            margin-right: 12px;
        }

        .toolboxScrollable {
            background-color: $darkSkinBaseColor;
        }
    }

    .rows {
        .theme-children {
            border-top: none;
        }
    }

    ::-webkit-scrollbar {
        width: 18px;
        height: 18px;
    }

    ::-webkit-scrollbar-track:vertical {
        background-color: lighten($darkSkinBaseColor, 10%);
        border-left: 1px solid $darkSkinBaseColor;
    }

    ::-webkit-scrollbar-track:horizontal {
        background-color: lighten($darkSkinBaseColor, 10%);
        border-top: 1px solid $darkSkinBaseColor;
    }

    ::-webkit-scrollbar-thumb {
        background-color: lighten($darkSkinBaseColor, 30%);
        border-radius: 1px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: lighten($darkSkinBaseColor, 50%);
    }

    ::-webkit-scrollbar-thumb:vertical {
        border-left: 1px solid $darkSkinBaseColor;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        border-top: 1px solid $darkSkinBaseColor;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-button {
        background-color: $darkSkinBaseColor;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 14px;
        transition: background-size 0.2s;
    }

    ::-webkit-scrollbar-button:hover {
        background-size: 18px;
    }

    .reportLabel {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    }

    .design-control-popout {
        background-color: $darkSkinBaseColor;
        color: $darkSkinTextColor;
        border-color: $darkSkinBaseColor;
    }

    .explanation,
    .toTopLink,
    .toTopLink.nodef {
        color: $darkSkinBaseColor;
    }

    .design-control-popout-holder {
        box-shadow: none;
    }

    .design-control-popout .toolboxTitle,
    .design-control-popper.active .fa,
    div.widget::before,
    div.widget::after,
    .label20,
    .modal-body label,
    fieldset legend {
        color: $darkSkinTextColor;
    }

    h4.open,
    h4.closed,
    .toolboxPanel .open,
    .toolboxPanel .closed .toolboxPanel h4 {
        background-color: var(--ia-app-hi-color);
        background-image: none;
        border-top: 1px solid lighten($darkSkinBaseColor, 10%);
        padding-bottom: 5px;
    }

    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus,
    .dropdown-menu .menu-sub-item:hover,
    .dropdown-menu .menu-sub-item:active,
    .dropdown-menu .active .menu-sub-item,
    .navbar li > a:hover,
    .navbar li > a:focus,
    .datasetActions > a:hover,
    .datasetActions > a:focus,
    .hoverable .hover-item:hover,
    .hoverable .hover-item:focus,
    .hoverable .hover-item.active {
        background-color: var(--ia-app-hi-color);
    }

    .explorerTree li.ind:hover label {
        color: $darkSkinBaseColor;
    }

    #availableWidgetsBox .widget {
        opacity: 1;
    }

    #availableWidgetsBox .widget:hover {
        background-color: lighten($darkSkinBaseColor, 10%);
        text-shadow: none;
    }

    .design-control-popper:not(.design-control-popper.active) {
        background-color: $darkSkinTextColor;
    }

    .design-control-popper:not(.design-control-popper.active) .fa {
        color: $darkSkinBaseColor;
    }

    .settingsTab:extend(.ia-app .modal-body .settingsTab) {
        color: $darkSkinBaseColor;
        border-bottom-color: $darkSkinBaseColor;
        background-color: $darkSkinTextColor;
    }

    .selectedTab {
        background-color: $darkSkinBaseColor;
        border-bottom-color: $darkSkinBaseColor;
        color: $darkSkinTextColor;
    }

    .modal .tabContent {
        background-color: $darkSkinBaseColor;
        color: $darkSkinTextColor;
    }

    .close:hover,
    .close:focus {
        color: $darkSkinTextColor;
        opacity: 0.75;
    }

    .ind,
    .drop-target:not(.explorerTree),
    .drag-source:not(.explorerTree) {
        border-color: transparent;
    }

    .data-locked .widgetIndicators .igroup-name,
    .data-locked .widgetIndicators .i-name,
    .data-locked .widgetIndicators .i-date {
        color: $darkSkinTextColor;
    }

    .explorerTree {
        border-radius: 0;
    }

    .explorerTree > div,
    .explorerTree > div > ul > li,
    .explorerTree div.widgetIndicators > ul > li {
        border-left: 1px solid #bbb;
    }

    .explorerTree > div:last-child,
    .explorerTree > div > ul > li:last-child,
    .explorerTree div.widgetIndicators > ul > li:last-child {
        border-left: none;
    }

    .btn,
    .form-control,
    .navbar-nav > li > a {
        border-radius: 1em;
    }

    .primary-action-link > .btn,
    .primary-action-link > .btn:hover,
    .primary-action-link > .btn:focus,
    .ia-app .arc-connected .new-report-button .btn {
        border-radius: 1em;
    }

    .btn,
    .btn-default {
        background-color: lighten($darkSkinBaseColor, 20%);
        border-color: lighten($darkSkinBaseColor, 10%);

        &.active {
            color: inherit;
        }

        &.btn-primary {
            @extend .btn-primary;
        }
    }

    .btn:disabled {
        color: lighten($darkSkinBaseColor, 50%);
    }

    #widgetSettingsPopup .settings-table td {
        height: 3.1em;
    }

    #widgetSettingsPopup .settings-table tr:hover {
        background-color: lighten($darkSkinBaseColor, 10%);
    }

    input,
    select {
        color: $darkSkinBaseColor;
    }

    .templateSelected,
    .arcItemSelected,
    li.templateSelected,
    li.arcItemSelected {
        box-shadow: inset 0 0 1px 1px $iaoActionColor, 0 0 4px 3px lighten($iaoActionColor, 10%);
    }

    .errMsg {
        border: none;
        background-color: #d24444;
        color: #dcdcdc;
        border-radius: $iaoBorderRadiusDef;
        padding: 7px;
    }

    #reportTemplateChooser {
        background-color: lighten($darkSkinBaseColor, 10%);
    }

    #designStandardHolder .foldtl {
        border-left: 36px solid #3b3b3b; /*Set to background color, not transparent!*/
    }

    #designStandardHolder .foldtr {
        border-right: 36px solid #3b3b3b; /*Set to background color, not transparent!*/
        box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.3), 6px 0 0 #3b3b3b, 0 -2px 0 #3b3b3b;
    }

    #designStandardHolder .foldtr::after,
    #designStandardHolder .foldtl::after {
        color: $darkSkinTextColor;
    }

    input:disabled,
    select:disabled {
        background-color: #dfdfdf;
        color: #aaa;
        opacity: 0.5;
    }

    button:disabled,
    input.btn:disabled {
        color: Gray;
        opacity: 0.5;
    }

    .profileReport,
    .agoMapTip {
        .tooltip-inner {
            background-color: $darkSkinBaseColor;
            color: $darkSkinTextColor;
        }

        .tooltip-arrow {
        }
    }

    .match-sample .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #ececec;
    }
}
